// import React from "react";
// import "./ContactUs.css";
// import { useTranslation } from "react-i18next";

// const ContactUs = () => {
//   const { t } = useTranslation("Translate");

//   const handleSubmit=()=>{

//   }
//   return (
//     <>
//       <div className="container-fluid py-5" id="Contact-us">
//         <span className="heading d-flex justify-content-center py-5">
//           {t("ContactUs")}
//         </span>
//         <div className=" container-fluid form">
//           <form onSubmit={handleSubmit}>
//             <div className="row ">
//               <div className="col-sm-6 col-md-6 col-xl-6  d-flex flex-column">
//                 <input
//                   type="text"
//                   placeholder={t("FirstName")}
//                   className="input-field"
//                 />
//                 <input
//                   type="text"
//                   placeholder={t("LastName")}
//                   className="input-field"
//                 />
//               </div>
//               <div className="col-sm-6 col-md-6 col-xl-6 d-flex flex-column">
//                 <input
//                   type="text"
//                   placeholder={t("Email")}
//                   className="input-field"
//                 />
//                 <input
//                   type="text"
//                   placeholder={t("Phone")}
//                   className="input-field"
//                 />
//               </div>
//             </div>
//             <textarea placeholder={t("Message")} className="textarea" />

//             <div className="secondary-text">
//               <span></span>
//               By submitting you accept our
//               <span className="term-condition"> Terms and Conditions</span>
//             </div>

//             <button className="btn-1" id="form-btn">
//               {t("Submit")}
//             </button>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ContactUs;


import React, { useState } from "react";
import "./ContactUs.css";
import { useTranslation } from "react-i18next";
import axios from "axios"; // You'll need to import Axios or your preferred HTTP client

const ContactUs = () => {
  const { t } = useTranslation("Translate");

  // Define state variables to hold form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
console.log(formData)
    // Send the form data to your API using an HTTP request (e.g., Axios)
    axios.post("YOUR_API_ENDPOINT", formData)
      .then((response) => {
        // Handle the response as needed (e.g., show a success message)
        console.log("Form data submitted successfully", response);
      })
      .catch((error) => {
        // Handle errors (e.g., show an error message)
        console.error("Form submission failed", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container-fluid py-5" id="Contact-us">
      <span className="heading d-flex justify-content-center lg:py-5">
        {t("ContactUs")}
      </span>
      <div className="container-fluid form">
        <form onSubmit={handleSubmit}>
          <div className="row ">
            <div className="col-sm-6 col-md-6 col-xl-6 d-flex flex-column">
              <input
                type="text"
                placeholder={t("FirstName")}
                className="input-field"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
               <input
                type="text"
                placeholder={t("Email")}
                className="input-field"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-sm-6 col-md-6 col-xl-6 d-flex flex-column">
             
                <input
                type="text"
                placeholder={t("LastName")}
                className="input-field"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              <input
                type="text"
                placeholder={t("Phone")}
                className="input-field"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <textarea
            placeholder={t("Message")}
            className="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />

          <div className="secondary-text">
            <span></span>
            {t("accept")}
            <span className="term-condition"> {t("TermsandConditions")}</span>
          </div>

          <button className="btn-1" id="form-btn" type="submit">
            {t("Submit")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
