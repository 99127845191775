import React, { useState } from "react";
import "./PrivacyPolicy.css";
import { useLocation } from "react-router-dom";
const PrivacyPolicyEng = () => {
  const { state } = useLocation();
  const [currentLang, setCurrentLang] = useState(state?.lang);

  return (
    <div className="privacy-policy">
      {currentLang === "en" ? (
        <div>
          <h1>Privacy Policy</h1>
          <h3>Data protection</h3>
          <p>
            <strong>Update:</strong> 27 July 2024
          </p>

          <h4>Table of contents</h4>
          <ul>
            <li>Controller</li>
            <li>Overview of the processing operations</li>
            <li>Relevant legal bases</li>
            <li>Security measures</li>
            <li>Transfer of personal data</li>
            <li>International data transfers</li>
            <li>Erasure of data</li>
            <li>Rights of the data subjects</li>
            <li>Use of cookies</li>
            <li>Business services</li>
            <li>Provision of the online offer and web hosting</li>
            <li>Registration, login and user account</li>
            <li>Contact and enquiry management</li>
            <li>Newsletter and electronic notifications</li>
            <li>Web analysis, monitoring and optimisation</li>
            <li>Presence in social networks (social media)</li>
            <li>Changing and updating the privacy policy</li>
            <li>Definitions of terms</li>
          </ul>

          <h3>Person responsible</h3>
          <p>
            Andre Genze
            <br />
            Röntgenstraße 5<br />
            52525 Heinsberg
            <br />
            Heinsberg, Germany
            <br />
            E-mail address:{" "}
            <a href="mailto:hallo@bartfreunde.de">hallo@bartfreunde.de</a>
          </p>

          <p>
            <strong>Imprint:</strong>
            <br />
            <a href="https://bartfreunde.de/impressum" target="_blank">
              https://bartfreunde.de/impressum
            </a>
          </p>

          <h3>Overview of the Processing Operations</h3>
          <p>
            The following overview summarises the types of data processed and
            the purposes of their processing and refers to the data subjects.
          </p>

          <h3>Types of data processed</h3>
          <ul>
            <li>Inventory data.</li>
            <li>Payment data.</li>
            <li>Contact data.</li>
            <li>Content data.</li>
            <li>Contract data.</li>
            <li>Usage data.</li>
            <li>Meta, communication and process data.</li>
          </ul>

          <h3>Categories of data subjects</h3>
          <ul>
            <li>Customers.</li>
            <li>Interested parties.</li>
            <li>Communication partners.</li>
            <li>Users.</li>
            <li>Business and contractual partners.</li>
          </ul>

          <h3>Purposes of the processing</h3>
          <ul>
            <li>
              Provision of contractual services and fulfilment of contractual
              obligations.
            </li>
            <li>Contact requests and communication.</li>
            <li>Security measures.</li>
            <li>Direct marketing.</li>
            <li>Reach measurement.</li>
            <li>Office and organisational procedures.</li>
            <li>Managing and responding to enquiries.</li>
            <li>Feedback.</li>
            <li>Marketing.</li>
            <li>Profiles with user-related information.</li>
            <li>Provision of our online services and user-friendliness.</li>
            <li>Information technology infrastructure.</li>
          </ul>

          <h3>Relevant legal bases</h3>
          <p>
            Relevant legal bases according to the GDPR: Below you will find an
            overview of the legal bases of the GDPR on the basis of which we
            process personal data. Please note that in addition to the
            provisions of the GDPR, national data protection regulations may
            apply in your or our country of residence or domicile. Should more
            specific legal bases also apply in individual cases, we will inform
            you of these in the privacy policy.
          </p>
          <ul>
            <li>
              Consent (Art. 6 para. 1 sentence 1 lit. a) GDPR) - The data
              subject has given their consent to the processing of their
              personal data for a specific purpose or several specific purposes.
            </li>

            <li>
              Contract fulfilment and pre-contractual enquiries (Art. 6 para. 1
              sentence 1 lit. b) GDPR) - Processing is necessary for the
              performance of a contract to which the data subject is party or in
              order to take steps at the request of the data subject prior to
              entering into a contract.
            </li>
            <li>
              Legal obligation (Art. 6 para. 1 sentence 1 lit. c) GDPR) -
              Processing is necessary for compliance with a legal obligation to
              which the controller is subject.
            </li>
            <li>
              Legitimate interests (Art. 6 para. 1 sentence 1 lit. f) GDPR) -
              Processing is necessary for the purposes of the legitimate
              interests pursued by the controller or by a third party, except
              where such interests are overridden by the interests or
              fundamental rights and freedoms of the data subject which require
              protection of personal data.
            </li>
          </ul>
          <p>
            National data protection regulations in Germany: In addition to the
            data protection regulations of the GDPR, national data protection
            regulations apply in Germany. These include, in particular, the Act
            on the Protection against Misuse of Personal Data in Data Processing
            (Federal Data Protection Act - BDSG). In particular, the BDSG
            contains special regulations on the right to information, the right
            to erasure, the right to object, the processing of special
            categories of personal data, processing for other purposes and
            transmission as well as automated decision-making in individual
            cases, including profiling. Furthermore, state data protection laws
            of the individual federal states may apply.
          </p>
          <p>
            Reference to the validity of the GDPR and Swiss FADP: This data
            protection notice serves to provide information in accordance with
            both the Swiss Federal Act on Data Protection (FADP) and the General
            Data Protection Regulation (GDPR). For this reason, please note that
            the terms of the GDPR are used due to the broader geographical
            application and comprehensibility. In particular, instead of the
            terms "processing" of "personal data", "overriding interest" and
            "sensitive personal data" used in the Swiss DPA, the terms
            "processing" of "personal data", "legitimate interest" and "special
            categories of data" used in the GDPR are used. However, the legal
            meaning of the terms will continue to be determined in accordance
            with the Swiss DPA within the scope of application of the Swiss DPA.
          </p>
          <h3>Security measures</h3>
          <p>
            We take appropriate technical and organisational measures in
            accordance with the legal requirements, taking into account the
            state of the art, the implementation costs and the nature, scope,
            circumstances and purposes of the processing as well as the
            different probabilities of occurrence and the extent of the threat
            to the rights and freedoms of natural persons, in order to ensure a
            level of protection appropriate to the risk.
          </p>
          <p>
            The measures include, in particular, safeguarding the
            confidentiality, integrity and availability of data by controlling
            physical and electronic access to the data as well as access, input,
            disclosure, safeguarding availability and separation of the data.
            Furthermore, we have established procedures that ensure the exercise
            of data subject rights, the deletion of data and responses to data
            threats. Furthermore, we take the protection of personal data into
            account as early as the development and selection of hardware,
            software and processes in accordance with the principle of data
            protection, through technology design and data protection-friendly
            default settings.
          </p>
          <p>
            TLS/SSL encryption (https): We use TLS/SSL encryption to protect
            user data transmitted via our online services. Secure Sockets Layer
            (SSL) is the standard technology for securing internet connections
            by encrypting the data transmitted between a website or app and a
            browser (or between two servers). Transport Layer Security (TLS) is
            an updated and more secure version of SSL. Hyper Text Transfer
            Protocol Secure (HTTPS) is displayed in the URL if a website is
            secured by an SSL/TLS certificate.
          </p>
          <p>
            Deletion of inactive user accounts: Applicant accounts that have had
            no activity for 12 months will be automatically and permanently
            deleted. The affected users will be contacted by email one month
            before the deletion process.
          </p>
          <p>
            Applicants have the option of uploading their application documents
            after a successful skills test. These documents are automatically
            deleted after 6 months regardless of the application status.
          </p>
          <h3>Transmission of personal data</h3>
          <p>
            As part of our processing of personal data, the data may be
            transferred to other bodies, companies, legally independent
            organisational units or persons or disclosed to them. The recipients
            of this data may include, for example, service providers
            commissioned with IT tasks or providers of services and content that
            are integrated into a website. In such cases, we observe the legal
            requirements and, in particular, conclude corresponding contracts or
            agreements with the recipients of your data that serve to protect
            your data.
          </p>
          <h3>International data transfers</h3>
          <p>
            Data processing in third countries: If we process data in a third
            country (i.e. outside the European Union (EU), the European Economic
            Area (EEA)) or if the processing takes place in the context of the
            use of third-party services or the disclosure or transfer of data to
            other persons, bodies or companies, this will only take place in
            accordance with the legal requirements. If the level of data
            protection in the third country has been recognised by means of an
            adequacy decision (Art. 45 GDPR), this serves as the basis for the
            data transfer. Otherwise, data will only be transferred if the level
            of data protection is otherwise ensured, in particular through
            standard contractual clauses (Art. 46 para. 2 lit. c) GDPR), express
            consent or in the case of contractual or legally required transfer
            (Art. 49 para. 1 GDPR). In addition, we will inform you of the basis
            for third country transfers with the individual providers from the
            third country, whereby the adequacy decisions take precedence.
            Information on third country transfers and existing adequacy
            decisions can be found in the information provided by the EU
            Commission:{" "}
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
              target="_blank"
            >
              https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de.
            </a>
          </p>
          <p>
            EU-US Trans-Atlantic Data Privacy Framework: As part of the
            so-called "Data Privacy Framework" (DPF), the EU Commission has also
            recognised the level of data protection for certain companies from
            the USA as secure as part of the adequacy decision of 10.07.2023.
            The list of certified companies and further information on the DPF
            can be found on the website of the US Department of Commerce at{" "}
            <a href="https://www.dataprivacyframework.gov/" target="_blank">
              https://www.dataprivacyframework.gov/.{" "}
            </a>{" "}
            As part of the data protection information, we will inform you which
            service providers we use are certified under the Data Privacy
            Framework.
          </p>
          <h3>Deletion of data</h3>
          <p>
            The data processed by us will be deleted in accordance with the
            legal requirements as soon as the consent given for processing is
            revoked or other authorisations no longer apply (e.g. if the purpose
            for processing this data no longer applies or it is not required for
            the purpose). If the data is not deleted because it is required for
            other and legally permissible purposes, its processing is restricted
            to these purposes. This means that the data is blocked and not
            processed for other purposes. This applies, for example, to data
            that must be retained for commercial or tax law reasons or whose
            storage is necessary for the assertion, exercise or defence of legal
            claims or to protect the rights of another natural or legal person.
            Our data protection notices may also contain further information on
            the retention and erasure of data, which take precedence for the
            respective processing operations.
          </p>

          <h3>Rights of the data subjects</h3>
          <p>
            Rights of data subjects under the GDPR: As a data subject, you are
            entitled to various rights under the GDPR, which arise in particular
            from Art. 15 to 21 GDPR:
          </p>

          <ul>
            <li>
              Right to object: you have the right to object, on grounds relating
              to your particular situation, at any time to processing of
              personal data concerning you which is based on point (e) or (f) of
              Article 6(1) GDPR, including profiling based on those provisions.
              If the personal data concerning you are processed for direct
              marketing purposes, you have the right to object at any time to
              the processing of personal data concerning you for such marketing,
              which includes profiling to the extent that it is related to such
              direct marketing.
            </li>
            <li>
              Right to withdraw consent: You have the right to withdraw any
              consent you have given at any time.
            </li>
            <li>
              Right to information: You have the right to request confirmation
              as to whether the data in question is being processed and to
              request information about this data as well as further information
              and a copy of the data in accordance with the legal requirements.
            </li>
            <li>
              Right to rectification: You have the right to request the
              completion of data concerning you or the rectification of
              inaccurate data concerning you in accordance with the legal
              requirements.
            </li>
            <li>
              Right to erasure and restriction of processing: In accordance with
              the legal requirements, you have the right to demand that data
              concerning you be erased immediately or, alternatively, to demand
              that the processing of the data be restricted in accordance with
              the legal requirements.
            </li>
            <li>
              Right to data portability: You have the right to receive the data
              concerning you that you have provided to us in a structured,
              commonly used and machine-readable format in accordance with the
              legal requirements or to request that it be transferred to another
              controller.
            </li>
            <li>
              Complaint to the supervisory authority: Without prejudice to any
              other administrative or judicial remedy, you have the right to
              lodge a complaint with a supervisory authority, in particular in
              the Member State of your habitual residence, place of work or
              place of the alleged infringement if you consider that the
              processing of personal data relating to you infringes the
              provisions of the GDPR.
            </li>
          </ul>
          <h3>Use of cookies</h3>
          <p>
            Cookies are small text files or other storage devices that store
            information on end devices and read information from the end
            devices. For example, to store the login status in a user account,
            the contents of a shopping basket in an e-shop, the content accessed
            or the functions of an online service used. Cookies can also be used
            for various purposes, e.g. to ensure the functionality, security and
            convenience of online offers and to create analyses of visitor
            flows.
          </p>
          <p>
            Notes on consent: We use cookies in accordance with the statutory
            provisions. We therefore obtain prior consent from users, unless
            this is not required by law. In particular, consent is not required
            if the storage and reading of information, including cookies, is
            absolutely necessary in order to provide the user with a telemedia
            service expressly requested by them (i.e. our online offering).
            Strictly necessary cookies generally include cookies with functions
            that serve the display and operability of the online service, load
            balancing, security, storage of user preferences and selection
            options or similar purposes related to the provision of the main and
            secondary functions of the online service requested by the user. The
            revocable consent is clearly communicated to the users and contains
            the information on the respective use of cookies.
          </p>
          <p>
            Information on legal bases under data protection law: The legal
            basis under data protection law on which we process users' personal
            data with the help of cookies depends on whether we ask users for
            their consent. If users give their consent, the legal basis for
            processing their data is the consent given. Otherwise, the data
            processed using cookies is processed on the basis of our legitimate
            interests (e.g. in the commercial operation of our online offering
            and improving its usability) or, if this is done as part of the
            fulfilment of our contractual obligations, if the use of cookies is
            necessary to fulfil our contractual obligations. We will explain the
            purposes for which we process cookies in the course of this privacy
            policy or as part of our consent and processing procedures.
          </p>
          <p>
            <strong>Storage duration:</strong> With regard to the storage
            duration, a distinction is made between the following types of
            cookies
          </p>
          <ul>
            <li>
              Temporary cookies (also: session or session cookies): Temporary
              cookies are deleted at the latest after a user has left an online
              service and closed their end device (e.g. browser or mobile
              application).
            </li>
            <li>
              Permanent cookies: Permanent cookies remain stored even after the
              end device is closed. For example, the login status can be saved
              or favourite content can be displayed directly when the user
              visits a website again. The user data collected with the help of
              cookies can also be used to measure reach. If we do not provide
              users with explicit information on the type and storage duration
              of cookies (e.g. when obtaining consent), users should assume that
              cookies are permanent and that they can be stored for up to two
              years. <br />
              General information on revocation and objection (so-called
              "opt-out"): Users can revoke the consent they have given at any
              time and object to processing in accordance with the legal
              requirements. Among other things, users can restrict the use of
              cookies in their browser settings (although this may also restrict
              the functionality of our online offering). An objection to the use
              of cookies for online marketing purposes can also be declared via
              the websites https://optout.aboutads.info and
              https://www.youronlinechoices.com/.
            </li>
            <li>
              Legal bases: Legitimate interests (Art. 6 para. 1 sentence 1 lit.
              f) GDPR). Consent (Art. 6 para. 1 sentence 1 lit. a) GDPR).
            </li>
          </ul>
          <h3>
            Further information on processing processes, procedures and
            services:
          </h3>
          <p>
            Processing of cookie data on the basis of consent: We use a cookie
            consent management procedure in which the consent of users to the
            use of cookies or the processing and providers named in the cookie
            consent management procedure can be obtained, managed and revoked by
            users. The declaration of consent is stored so that it does not have
            to be requested again and the consent can be proven in accordance
            with the legal obligation. The consent can be stored on the server
            and/or in a cookie (so-called opt-in cookie or with the help of
            comparable technologies) in order to be able to assign the consent
            to a user or their device. Subject to individual information on the
            providers of cookie management services, the following information
            applies: Consent may be stored for up to two years. A pseudonymous
            user identifier is created and stored with the time of consent,
            information on the scope of consent (e.g. which categories of
            cookies and/or service providers) as well as the browser, system and
            end device used; legal basis: consent (Art. 6 para. 1 sentence 1
            lit. a) GDPR).
          </p>
          <h4>Business services</h4>
          <p>
            We process data of our contractual and business partners, e.g.
            customers and interested parties (collectively referred to as
            "contractual partners") in the context of contractual and comparable
            legal relationships and associated measures and in the context of
            communication with the contractual partners (or pre-contractual),
            e.g. to answer enquiries.
          </p>
          <p>
            We process this data in order to fulfil our contractual obligations.
            These include, in particular, the obligations to provide the agreed
            services, any updating obligations and remedies in the event of
            warranty and other service disruptions. In addition, we process the
            data to safeguard our rights and for the purpose of the
            administrative tasks associated with these obligations and the
            company organisation. In addition, we process the data on the basis
            of our legitimate interests in proper and efficient business
            management and in security measures to protect our contractual
            partners and our business operations from misuse, jeopardising their
            data, secrets, information and rights (e.g. for the involvement of
            telecommunications, transport and other auxiliary services as well
            as subcontractors, banks, tax and legal advisors, payment service
            providers or tax authorities). Within the framework of applicable
            law, we only pass on the data of contractual partners to third
            parties to the extent that this is necessary for the aforementioned
            purposes or to fulfil legal obligations. Contractual partners will
            be informed about other forms of processing, e.g. for marketing
            purposes, as part of this privacy policy.
          </p>
          <p>
            We inform the contractual partners which data is required for the
            aforementioned purposes before or during data collection, e.g. in
            online forms, by means of special marking (e.g. colours) or symbols
            (e.g. asterisks or similar), or in person.
          </p>
          <p>
            We delete the data after the expiry of statutory warranty and
            comparable obligations, i.e. generally after 4 years, unless the
            data is stored in a customer account, e.g. as long as it must be
            retained for legal archiving reasons. The statutory retention period
            is ten years for documents relevant under tax law and for trading
            books, inventories, opening balance sheets, annual financial
            statements, the work instructions required to understand these
            documents and other organisational documents and accounting records,
            and six years for commercial and business letters received and
            reproductions of commercial and business letters sent. The period
            begins at the end of the calendar year in which the last entry was
            made in the book, the inventory, the opening balance sheet, the
            annual financial statements or the management report was prepared,
            the commercial or business letter was received or sent or the
            accounting document was created, the record was made or the other
            documents were created.
          </p>
          <p>
            Insofar as we use third-party providers or platforms to provide our
            services, the terms and conditions and data protection notices of
            the respective third-party providers or platforms apply in the
            relationship between the users and the providers.
          </p>
          <ul>
            <li>
              Processed data types: Inventory data (e.g. names, addresses);
              payment data (e.g. bank details, invoices, payment history);
              contact data (e.g. email, telephone numbers); contract data (e.g.
              subject matter of the contract, duration, customer category);
              usage data (e.g. websites visited, interest in content, access
              times); meta, communication and process data (e.g. IP addresses,
              time data, identification numbers, consent status).
            </li>
            <li>
              Data subjects: Customers; interested parties. Business and
              contractual partners.
            </li>
            <li>
              Purposes of processing: Provision of contractual services and
              fulfilment of contractual obligations; security measures; contact
              requests and communication; office and organisational procedures.
              Managing and responding to enquiries.
            </li>
            <li>
              Legal bases: Contract fulfilment and pre-contractual enquiries
              (Art. 6 Para. 1 S. 1 lit. b) GDPR); Legal obligation (Art. 6 Para.
              1 S. 1 lit. c) GDPR). Legitimate interests (Art. 6 para. 1
              sentence 1 lit. f) GDPR).
            </li>
          </ul>
          <h3>
            Further information on processing processes, procedures and
            services:
          </h3>
          <p>
            Customer account: Customers can create an account within our online
            offering (e.g. customer or user account, "customer account" for
            short). If the registration of a customer account is required,
            customers will be informed of this and of the information required
            for registration. Customer accounts are not public and cannot be
            indexed by search engines. As part of the registration process and
            subsequent logins and use of the customer account, we store the IP
            addresses of customers together with the access times in order to be
            able to prove registration and prevent any misuse of the customer
            account. If the customer account has been cancelled, the customer
            account data will be deleted after the cancellation date, unless it
            is stored for purposes other than provision in the customer account
            or must be stored for legal reasons (e.g. internal storage of
            customer data, order processes or invoices). It is the customer's
            responsibility to back up their data when cancelling the customer
            account; legal basis: contract fulfilment and pre-contractual
            enquiries (Art. 6 para. 1 sentence 1 lit. b) GDPR).
          </p>
          <h3>Provision of the online offer and web hosting</h3>
          <p>
            We process users' data in order to provide them with our online
            services. For this purpose, we process the user's IP address, which
            is necessary to transmit the content and functions of our online
            services to the user's browser or end device.
          </p>
          <ul>
            <li>
              <strong>Processed data types:</strong> Usage data (e.g. websites
              visited, interest in content, access times); meta, communication
              and procedural data (e.g. IP addresses, time data, identification
              numbers, consent status); content data (e.g. .e.g. entries in
              online forms).
            </li>
            <li>
              <strong>Data subjects:</strong> Users (e.g. website visitors,
              users of online services).
            </li>
            <li>
              <strong>Purposes of processing: </strong> Provision of our online
              services and user-friendliness; information technology
              infrastructure (operation and provision of information systems and
              technical devices (computers, servers, etc.)). Security measures.
            </li>
            <li>
              <strong>Legal bases: </strong> Legitimate interests (Art. 6 para.
              1 sentence 1 lit. f) GDPR).
            </li>
          </ul>
          <h3>
            Further information on processing processes, procedures and
            services:
          </h3>
          <ul>
            <li>
              Provision of online offer on rented storage space: For the
              provision of our online offer, we use storage space, computing
              capacity and software that we rent or otherwise obtain from a
              corresponding server provider (also called "web host"); legal
              basis: Legitimate interests (Art. 6 para. 1 sentence 1 lit. f)
              GDPR).
            </li>
            <li>
              Collection of access data and log files: Access to our online
              offering is logged in the form of so-called "server log files".
              The server log files may include the address and name of the web
              pages and files accessed, date and time of access, data volumes
              transferred, notification of successful access, browser type and
              version, the user's operating system, referrer URL (the previously
              visited page) and, as a rule, IP addresses and the requesting
              provider. The server log files may be used for security purposes,
              e.g. to avoid overloading the servers (especially in the event of
              abusive attacks, so-called DDoS attacks) and to ensure the
              utilisation of the servers and their stability; legal basis:
              Legitimate interests (Art. 6 para. 1 sentence 1 lit. f) GDPR).
              Deletion of data: Log file information is stored for a maximum of
              30 days and then deleted or anonymised. Data whose further storage
              is required for evidentiary purposes is excluded from deletion
              until the respective incident has been finally clarified.
            </li>
            <li>
              E-mail dispatch and hosting: The web hosting services we use also
              include the dispatch, receipt and storage of e-mails. For these
              purposes, the addresses of the recipients and senders as well as
              other information relating to the sending of e-mails (e.g. the
              providers involved) and the content of the respective e-mails are
              processed. The aforementioned data may also be processed for the
              purpose of recognising SPAM. Please note that e-mails on the
              Internet are generally not sent in encrypted form. As a rule,
              emails are encrypted in transit, but not on the servers from which
              they are sent and received (unless an end-to-end encryption method
              is used). We can therefore assume no responsibility for the
              transmission path of the e-mails between the sender and receipt on
              our server; legal basis: Legitimate interests (Art. 6 para. 1
              sentence 1 lit. f) GDPR).
            </li>
            <li>
              CONTABO: Services in the field of the provision of information
              technology infrastructure and related services (e.g. storage space
              and/or computing capacities); Service provider: Contabo GmbH,
              Aschauer Straße 32a, 81549 München, Germany; Legal basis:
              Legitimate interests (Art. 6 para. 1 sentence 1 lit. f) GDPR);
              Website: https://www.contabo.com; Privacy Policy:
              https://contabo.com/de/legal/privacy/. Data processing agreement:
              Provided by the service provider.
            </li>
          </ul>
          <h5>Registration, login and user account</h5>
          <p>
            Users can create a user account. As part of the registration
            process, users are provided with the required mandatory information
            and processed for the purpose of providing the user account on the
            basis of contractual fulfilment of obligations. The processed data
            includes in particular the login information (user name, password
            and an e-mail address).
          </p>
          <p>
            As part of the use of our registration and login functions and the
            use of the user account, we store the IP address and the time of the
            respective user action. This data is stored on the basis of our
            legitimate interests and those of the user in protection against
            misuse and other unauthorised use. This data is not passed on to
            third parties unless it is necessary to pursue our claims or there
            is a legal obligation to do so.
          </p>
          <p>
            Users may be informed by email about processes that are relevant to
            their user account, such as technical changes.
          </p>
          <ul>
            <li>
              <strong>Processed data types:</strong>Inventory data (e.g. names,
              addresses); contact data (e.g. email, telephone numbers); content
              data (e.g. entries in online forms); meta, communication and
              process data (e.g. IP addresses, time data, identification
              numbers, consent status).
            </li>
            <li>
              <strong>Data subjects:</strong> Users (e.g. website visitors,
              users of online services).
            </li>
            <li>
              <strong>Purposes of processing: </strong> Provision of contractual
              services and fulfilment of contractual obligations; security
              measures; managing and responding to enquiries. Provision of our
              online services and user-friendliness.
            </li>
            <li>
              <strong>Legal bases: </strong> Contract fulfilment and
              pre-contractual enquiries (Art. 6 para. 1 sentence 1 lit. b)
              GDPR). Legitimate interests (Art. 6 para. 1 sentence 1 lit. f)
              GDPR).
            </li>
          </ul>

          <h3>
            Further information on processing processes, procedures and
            services:
          </h3>
          <ul>
            <li>
              Registration with a real name: Due to the nature of our community,
              we ask users to only use our services using real names. This means
              that the use of pseudonyms is not permitted; legal basis:
              fulfilment of contract and pre-contractual enquiries (Art. 6 para.
              1 sentence 1 lit. b) GDPR).
            </li>
            <li>
              User profiles are not public: User profiles are not visible or
              accessible to the public.
            </li>
            <li>
              Setting the visibility of profiles: Users can use settings to
              determine the extent to which their profiles are visible or
              accessible to the public or only to certain groups of people;
              legal basis: fulfilment of contract and pre-contractual enquiries
              (Art. 6 para. 1 sentence 1 lit. b) GDPR).
            </li>
            <li>
              Deletion of data after cancellation: If users have cancelled their
              user account, their data with regard to the user account will be
              deleted, subject to legal permission, obligation or consent of the
              users; legal bases: contract fulfilment and pre-contractual
              enquiries (Art. 6 para. 1 sentence 1 lit. b) GDPR).
            </li>
          </ul>
          <h3>Contact and enquiry management</h3>
          <p>
            When contacting us (e.g. by post, contact form, email, telephone or
            via social media) and in the context of existing user and business
            relationships, the details of the enquiring persons are processed
            insofar as this is necessary to answer the contact enquiries and any
            requested measures.
          </p>
          <ul>
            <li>
              <strong>Processed data types:</strong> Contact data (e.g. email,
              telephone numbers); Content data (e.g. entries in online forms);
              Usage data (e.g. websites visited, interest in content, access
              times); Meta, communication and process data (e.g. IP addresses,
              time data, identification numbers, consent status).
            </li>
            <li>
              <strong>Data subjects:</strong> Communication partners.
            </li>
            <li>
              <strong>Purposes of processing: </strong> Contact requests and
              communication; Managing and responding to enquiries; Feedback
              (e.g. collecting feedback via online form). Provision of our
              online services and user-friendliness.
            </li>
            <li>
              <strong>Legal bases: </strong>Legitimate interests (Art. 6 para. 1
              sentence 1 lit. f) GDPR). Contract fulfilment and pre-contractual
              enquiries (Art. 6 para. 1 sentence 1 lit. b) GDPR).
            </li>
          </ul>
          <h5>
            Further information on processing processes, procedures and
            services:
          </h5>
          <ul>
            {" "}
            <li>
              <strong>Contact form: </strong> If users contact us via our
              contact form, e-mail or other communication channels, we process
              the data provided to us in this context to process the
              communicated request; legal basis: fulfilment of contract and
              pre-contractual enquiries (Art. 6 para. 1 sentence 1 lit. b)
              GDPR), legitimate interests (Art. 6 para. 1 sentence 1 lit. f)
              GDPR).
            </li>
          </ul>
          <h3>Newsletter and electronic notifications</h3>
          <p>
            We only send newsletters, emails and other electronic notifications
            (hereinafter "newsletter") with the consent of the recipient or with
            legal authorisation. If the contents of the newsletter are
            specifically described when registering for the newsletter, they are
            decisive for the user's consent. Otherwise, our newsletters contain
            information about our services and us.
          </p>
          <p>
            To subscribe to our newsletters, it is generally sufficient to
            provide your e-mail address. However, we may ask you to provide a
            name so that we can address you personally in the newsletter, or
            other information if this is necessary for the purposes of the
            newsletter.
          </p>
          <p>
            Double opt-in procedure: Registration for our newsletter is always
            carried out in a so-called double opt-in procedure. This means that
            after registering, you will receive an email asking you to confirm
            your registration. This confirmation is necessary so that no-one can
            register with other people's e-mail addresses. Subscriptions to the
            newsletter are logged in order to be able to prove the registration
            process in accordance with legal requirements. This includes storing
            the time of registration and confirmation as well as the IP address.
            Changes to your data stored by the mailing service provider are also
            logged.
          </p>
          <p>
            Deletion and restriction of processing: We may store the
            unsubscribed email addresses for up to three years on the basis of
            our legitimate interests before deleting them in order to be able to
            prove that consent was previously given. The processing of this data
            is limited to the purpose of a possible defence against claims. An
            individual request for erasure is possible at any time, provided
            that the former existence of consent is confirmed at the same time.
            In the event of obligations to permanently observe objections, we
            reserve the right to store the e-mail address in a block list solely
            for this purpose.
          </p>
          <p>
            The registration process is logged on the basis of our legitimate
            interests for the purpose of proving that it has been carried out
            properly. If we commission a service provider to send e-mails, this
            is done on the basis of our legitimate interests in an efficient and
            secure dispatch system.
          </p>
          <h5>Contents:</h5>
          <p>
            Information about us, our services, promotions and offers. We also
            send notifications by e-mail as soon as a new job advert is posted
            online, after a positive skills test and after application documents
            have been provided. Users (registered applicants and employers) can
            change the notifications in their customer account.
          </p>

          <ul>
            <li>
              <strong>Processed data types:</strong> Inventory data (e.g. names,
              addresses); contact data (e.g. email, telephone numbers); meta,
              communication and process data (e.g. IP addresses, time data,
              identification numbers, consent status).
            </li>
            <li>
              <strong>Data subjects:</strong> Communication partners.
            </li>
            <li>
              <strong>Purposes of processing: </strong> Direct marketing (e.g.
              by email or post).
            </li>
            <li>
              <strong>Legal bases: </strong>Consent (Art. 6 para. 1 sentence 1
              lit. a) GDPR).
            </li>
            <li>
              <strong>Legal bases: </strong> You can cancel the receipt of our
              newsletter at any time, i.e. revoke your consent or object to
              further receipt. You will find a link to unsubscribe from the
              newsletter either at the end of each newsletter or you can use one
              of the contact options listed above, preferably email.
            </li>
          </ul>

          <h3>Web analysis, monitoring and optimisation</h3>
          <p>
            Web analysis (also referred to as "reach measurement") is used to
            evaluate the flow of visitors to our online offering and may include
            behaviour, interests or demographic information about visitors, such
            as age or gender, as pseudonymous values. With the help of reach
            analysis, we can, for example, recognise at what time our online
            offering or its functions or content are most frequently used or
            invite visitors to reuse them. We can also understand which areas
            require optimisation.
          </p>
          <p>
            In addition to web analysis, we may also use test procedures, e.g.
            to test and optimise different versions of our online offering or
            its components.
          </p>
          <p>
            Unless otherwise stated below, profiles, i.e. data summarised for a
            usage process, may be created for these purposes and information may
            be stored in a browser or end device and read from it. The
            information collected includes, in particular, websites visited and
            the elements used there as well as technical information such as the
            browser used, the computer system used and information on usage
            times. If users have consented to the collection of their location
            data from us or from the providers of the services we use, location
            data may also be processed.
          </p>
          <p>
            The IP addresses of users are also stored. However, we use an IP
            masking procedure (i.e. pseudonymisation by shortening the IP
            address) to protect users. In general, no clear user data (such as
            email addresses or names) is stored in the context of web analysis,
            A/B testing and optimisation, but pseudonyms. This means that
            neither we nor the providers of the software used know the actual
            identity of the users, but only the information stored in their
            profiles for the purposes of the respective processes.
          </p>

          <ul>
            <li>
              <strong>Processed data types:</strong> Usage data (e.g. websites
              visited, interest in content, access times); meta, communication
              and procedural data (e.g. .e.g. IP addresses, time data,
              identification numbers, consent status).
            </li>
            <li>
              <strong>Data subjects:</strong> Users (e.g. website visitors,
              users of online services).
            </li>
            <li>
              <strong>Purposes of processing: </strong> Reach measurement (e.g.
              access statistics, recognition of returning visitors); profiles
              with user-related information (creation of user profiles).
              Provision of our online services and user-friendliness.
            </li>
            <li>
              <strong>Security measures: </strong>IP masking (pseudonymisation
              of the IP address).
            </li>
            <li>
              <strong>Legal bases: </strong>Legitimate interests (Art. 6 para. 1
              sentence 1 lit. f) GDPR).
            </li>
          </ul>
          <h4>
            Further information on processing operations, procedures and
            services:
          </h4>
          <p>
            <strong>- Google Analytics 4:</strong>{" "}
          </p>
          <p>
            We use Google Analytics to measure and analyse the use of our online
            offering on the basis of a pseudonymous user identification number.
            This identification number does not contain any unique data, such as
            names or email addresses. It is used to assign analysis information
            to an end device in order to recognise which content users have
            called up within one or more usage processes, which search terms
            they have used, which they have called up again or which they have
            interacted with our online offering. The time of use and its
            duration are also stored, as well as the sources of the users who
            refer to our online offering and technical aspects of their end
            devices and browsers. Pseudonymised user profiles are created with
            information from the use of various devices, whereby cookies may be
            used. Google Analytics does not log or store individual IP addresses
            for EU users. However, Analytics provides rough geographic location
            data by deriving the following metadata from IP addresses: City (and
            the city's inferred latitude and longitude), Continent, Country,
            Region, Subcontinent (and ID-based counterparts). For EU traffic,
            the IP address data is used exclusively for this derivation of
            geolocalisation data before it is immediately deleted. It is not
            logged, is not accessible and is not used for other purposes. When
            Google Analytics collects measurement data, all IP queries are
            performed on EU-based servers before the traffic is forwarded to
            Analytics servers for processing; service provider: Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Ireland; Legal
            basis: Legitimate interests (Art. 6 para. 1 sentence 1 lit. f)
            GDPR);{" "}
            <p>
              Website:{" "}
              <a
                href="https://marketingplatform.google.com/intl/de/about/analytics/"
                target="_blank"
              >
                https://marketingplatform.google.com/intl/de/about/analytics/;{" "}
              </a>
            </p>
            <p>
              Privacy Policy:{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                https://policies.google.com/privacy
              </a>
            </p>
            <p>
              Data Processing Agreement:{" "}
              <a
                href="https://business.safety.google/adsprocessorterms/"
                target="_blank"
              >
                https://business.safety.google/adsprocessorterms/;{" "}
              </a>
            </p>
            <p>
              Basis for Third Country Transfer: EU-US Data Privacy Framework
              (DPF), Standard Contractual Clauses{" "}
              <a
                href="https://business.safety.google/adsprocessorterms"
                target="_blank"
              >
                (https://business.safety.google/adsprocessorterms);{" "}
              </a>
            </p>
            <p>
              Opt-Out:
              <ul>
                <li>
                  Opt-out plugin:{" "}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=de"
                    target="_blank"
                  >
                    https://tools.google.com/dlpage/gaoptout?hl=de,
                  </a>
                </li>
                <li>
                  Settings for the display of adverts:{" "}
                  <a
                    href="https://adssettings.google.com/authenticated"
                    target="_blank"
                  >
                    https://adssettings.google.com/authenticated
                  </a>
                </li>
              </ul>
            </p>
            <p>
              Further Information:{" "}
              <a
                href="https://business.safety.google/adsservices/"
                target="_blank"
              >
                https://business.safety.google/adsservices/{" "}
              </a>
            </p>
          </p>

          <h3>Presence in social networks (social media)</h3>
          <p>
            We maintain online presences within social networks and process user
            data in this context in order to communicate with the users active
            there or to offer information about us.
          </p>
          <p>
            We would like to point out that user data may be processed outside
            the European Union. This may result in risks for users because, for
            example, it could make it more difficult to enforce users' rights.
          </p>
          <p>
            Furthermore, user data within social networks is generally processed
            for market research and advertising purposes. For example, user
            profiles can be created based on user behaviour and the resulting
            interests of users. The user profiles can in turn be used, for
            example, to place adverts within and outside the networks that
            presumably correspond to the interests of the users. For these
            purposes, cookies are generally stored on the user's computer, in
            which the user's usage behaviour and interests are stored.
            Furthermore, data can also be stored in the user profiles
            independently of the devices used by the users (especially if the
            users are members of the respective platforms and are logged in to
            them).
          </p>
          <p>
            For a detailed description of the respective forms of processing and
            the opt-out options, please refer to the data protection
            declarations and information provided by the operators of the
            respective networks.
          </p>
          <p>
            In the case of requests for information and the assertion of data
            subject rights, we would also like to point out that these can be
            asserted most effectively with the providers. Only the providers
            have access to the users' data and can take appropriate measures and
            provide information directly. Should you nevertheless require
            assistance, you can contact us.
          </p>
          <ul>
            <li>
              <strong>Processed data types:</strong> Contact data (e.g. e-mail,
              telephone numbers); Content data (e.g. entries in online forms);
              Usage data (e.g. websites visited, interest in content, access
              times); Meta, communication and process data (e.g. IP addresses,
              time data, identification numbers, consent status).
            </li>
            <li>
              <strong>Data subjects:</strong>Users (e.g. website visitors, users
              of online services).
            </li>
            <li>
              <strong>Purposes of processing: </strong> Contact requests and
              communication; Feedback (e.g. collecting feedback via online
              form). Marketing.
            </li>

            <li>
              <strong>Legal bases: </strong>Legitimate interests (Art. 6 para. 1
              sentence 1 lit. f) GDPR).
            </li>
          </ul>
          <h5>
            Further information on processing processes, procedures and
            services:
          </h5>
          <ul>
            <li>
              <p>Instagram: Social network</p>
              <p>
                Service provider: Meta Platforms Ireland Limited, 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Ireland
              </p>
              <p>
                Legal basis: Legitimate interests (Art. 6 para. 1 sentence 1
                lit. f) GDPR)
              </p>
              <p>
                Website:{" "}
                <a href="https://www.instagram.com" target="_blank">
                  Instagram
                </a>
              </p>
              <p>
                Privacy Policy:{" "}
                <a
                  href="https://instagram.com/about/legal/privacy"
                  target="_blank"
                >
                  Instagram Privacy Policy
                </a>
              </p>
            </li>
            <li>
              <p>Facebook pages: Profiles within the social network Facebook</p>
              <p>
                We are jointly responsible with Meta Platforms Ireland Limited
                for the collection (but not the further processing) of data from
                visitors to our Facebook page (so-called "fan page"). This data
                includes information about the types of content users view or
                interact with, or the actions they take (see under "Things you
                and others do and provide" in the Facebook Data Policy:{" "}
                <a href="https://www.facebook.com/policy" target="_blank">
                  Facebook Data Policy
                </a>
                ), as well as information about the devices used by users (e.g.
                IP addresses, operating system, browser type, language settings,
                cookie data; see under "Device information" in the Facebook Data
                Policy:{" "}
                <a href="https://www.facebook.com/policy" target="_blank">
                  Facebook Data Policy
                </a>
                ).
              </p>
              <p>
                As explained in the Facebook Data Policy under "How do we use
                this information?", Facebook also collects and uses information
                to provide analytics services, known as "Page Insights", for
                page operators so that they can gain insights into how people
                interact with their pages and the content associated with them.
                We have concluded a special agreement with Facebook
                ("Information on Page Insights",{" "}
                <a
                  href="https://www.facebook.com/legal/terms/page_controller_addendum"
                  target="_blank"
                >
                  Information on Page Insights
                </a>
                ), which regulates in particular which security measures
                Facebook must observe and in which Facebook has agreed to fulfil
                the rights of data subjects (i.e. users can, for example, send
                information or deletion requests directly to Facebook).
              </p>
              <p>
                The rights of users (in particular to information, deletion,
                objection and complaint to the competent supervisory authority)
                are not restricted by the agreements with Facebook. Further
                information can be found in the "Information on Page Insights" (
                <a
                  href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                  target="_blank"
                >
                  Information on Page Insights
                </a>
                ).
              </p>
              <p>
                Service provider: Meta Platforms Ireland Limited, Merrion Road,
                Dublin 4, D04 X2K5, Ireland
              </p>
              <p>
                Legal basis: Legitimate interests (Art. 6 para. 1 sentence 1
                lit. f) GDPR)
              </p>
              <p>
                Website:{" "}
                <a href="https://www.facebook.com" target="_blank">
                  Facebook
                </a>
              </p>
              <p>
                Privacy Policy:{" "}
                <a
                  href="https://www.facebook.com/about/privacy"
                  target="_blank"
                >
                  Facebook Privacy Policy
                </a>
              </p>
              <p>
                Basis for transfer to third countries: EU-US Data Privacy
                Framework (DPF), Standard Contractual Clauses (
                <a
                  href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                  target="_blank"
                >
                  SCCs
                </a>
                )
              </p>
              <p>
                Further information: Agreement on joint controllership:{" "}
                <a
                  href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                  target="_blank"
                >
                  Agreement on Joint Controllership
                </a>
              </p>
              <p>
                The joint controllership is limited to the collection by and
                transfer of data to Meta Platforms Ireland Limited, a company
                based in the EU. The further processing of the data is the sole
                responsibility of Meta Platforms Ireland Limited, which in
                particular concerns the transfer of the data to the parent
                company Meta Platforms, Inc. in the USA (on the basis of the
                standard contractual clauses concluded between Meta Platforms
                Ireland Limited and Meta Platforms, Inc.).
              </p>
            </li>
          </ul>
          <h3>Changing and updating the privacy policy</h3>
          <p>
            We ask you to inform yourself regularly about the content of our
            privacy policy. We will amend the privacy policy as soon as changes
            to the data processing carried out by us make this necessary. We
            will inform you as soon as the changes require your co-operation
            (e.g. consent) or other individual notification.
          </p>
          <p>
            If we provide addresses and contact information of companies and
            organisations in this privacy policy, please note that the addresses
            may change over time and ask you to check the information before
            contacting us.
          </p>
          <h5>Definitions of terms</h5>
          <p>
            This section provides you with an overview of the terms used in this
            privacy policy. Insofar as the terms are defined by law, their legal
            definitions apply. However, the following explanations are primarily
            intended to aid understanding.
          </p>
          <ul>
            <li>
              <strong>Personal data:</strong> "Personal data" means any
              information relating to an identified or identifiable natural
              person (hereinafter "data subject"); an identifiable natural
              person is one who can be identified, directly or indirectly, in
              particular by reference to an identifier such as a name, an
              identification number, location data, an online identifier (e.g.
              cookie) or to one or more factors specific to the physical,
              physiological, genetic, mental, economic, cultural or social
              identity of that natural person.
            </li>

            <li>
              <strong>Profiles with user-related information:</strong> The
              processing of "profiles with user-related information", or
              "profiles" for short, includes any type of automated processing of
              personal data that consists of using this personal data to
              analyse, evaluate or predict certain personal aspects relating to
              a natural person (depending on the type of profiling, this may
              include various information relating to demographics, behaviour
              and interests, such as interaction with websites and their
              content, etc.) (e.g. interests in certain content or products,
              click behaviour on a website or location). Cookies and web beacons
              are often used for profiling purposes.
            </li>

            <li>
              <strong>Reach measurement:</strong> Reach measurement (also known
              as web analytics) is used to evaluate the flow of visitors to an
              online offering and can include the behaviour or interests of
              visitors in certain information, such as website content. With the
              help of reach analysis, operators of online services can, for
              example, recognise at what time users visit their websites and
              what content they are interested in. This enables them, for
              example, to better customise the content of their websites to the
              needs of their visitors. Pseudonymous cookies and web beacons are
              often used for reach analysis purposes in order to recognise
              returning visitors and thus obtain more precise analyses of the
              use of an online offering.
            </li>

            <li>
              <strong>Controller:</strong> "Controller" means the natural or
              legal person, public authority, agency or other body which, alone
              or jointly with others, determines the purposes and means of the
              processing of personal data.
            </li>

            <p>
              <strong>Processing:</strong> "Processing" means any operation or
              set of operations which is performed on personal data or on sets
              of personal data, whether or not by automated means. The term is
              broad and covers practically every handling of data, be it
              collection, analysis, storage, transmission or erasure.
            </p>
          </ul>

          <p>
            Created with free Datenschutz-Generator.de by Dr Thomas Schwenke
          </p>
        </div>
        ) : (
        <div>
        <h1>Datenschutzerklärung</h1>
      <h2>Präambel</h2>
      <p>
        Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
        aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch
        kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem
        Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns
        durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen
        der Erbringung unserer Leistungen als auch insbesondere auf unseren
        Webseiten, in mobilen Applikationen sowie innerhalb externer
        Onlinepräsenzen, wie z. B. unserer Social-Media-Profile (nachfolgend
        zusammenfassend bezeichnet als "Onlineangebot").
      </p>
      <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
      <p>Stand: 1. Juli 2024</p>
      <h2>Inhaltsübersicht</h2>
      <ul>
        <li>
          Präambel
        </li>
        <li>
          Verantwortlicher
        </li>
        <li>
          Übersicht der Verarbeitungen
        </li>
        <li>
          Maßgebliche Rechtsgrundlagen
        </li>
        <li>
          Sicherheitsmaßnahmen
        </li>
        <li>
      
            Übermittlung von personenbezogenen Daten
        
        </li>
        <li>
          Internationale Datentransfers
        </li>
        <li>
         
            Allgemeine Informationen zur Datenspeicherung und Löschung
          
        </li>
        <li>
         Rechte der betroffenen Personen
        </li>
        <li>
         Geschäftliche Leistungen
        </li>
        <li>
          Geschäftsprozesse und -verfahren
        </li>
        <li>
     
            Bereitstellung des Onlineangebots und Webhosting
          
        </li>
        <li>
         Einsatz von Cookies
        </li>
        <li>

            Registrierung, Anmeldung und Nutzerkonto
          
        </li>
        <li>
       Kontakt- und Anfrageverwaltung
        </li>
        <li>
          Push-Nachrichten
        </li>
        <li>
          
            Newsletter und elektronische Benachrichtigungen
          
        </li>
        <li>
    
            Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
          
        </li>
        <li>
          Gewinnspiele und Wettbewerbe
        </li>
        <li>
          
            Webanalyse, Monitoring und Optimierung
          
        </li>
        <li>
       
          Onlinemarketing
        </li>
        <li>
        
            Präsenzen in sozialen Netzwerken (Social Media)
          
        </li>
        <li>
          
            Plug-ins und eingebettete Funktionen sowie Inhalte
          
        </li>
        <li>
         Änderung und Aktualisierung
        </li>
        <li>
          Begriffsdefinitionen
        </li>
      </ul>
      <h2>Verantwortlicher</h2>
      <p>
        Bartfreunde.de
        <br />
        Andre Genze
        <br />
        Röntgenstraße
        <br />
        52525 Heinsberg
        <br />
        Deutschland
        <br />
        Vertretungsberechtigte Personen: Andre Genze
        <br />
        E-Mail-Adresse: hallo@bartfreunde.de
        <br />
        Impressum:{" "}
        <a
          href="https://www.bartfreunde.de/imprint"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.bartfreunde.de/imprint
        </a>
      </p>
      <h2>Übersicht der Verarbeitungen</h2>
      <p>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
        die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
        Personen.
      </p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>Bestandsdaten.</li>
        <li>Zahlungsdaten.</li>
        <li>Standortdaten.</li>
        <li>Kontaktdaten.</li>
        <li>Inhaltsdaten.</li>
        <li>Vertragsdaten.</li>
        <li>Nutzungsdaten.</li>
        <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
        <li>Event-Daten (Facebook).</li>
        <li>Protokolldaten.</li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>Leistungsempfänger und Auftraggeber.</li>
        <li>Interessenten.</li>
        <li>Kommunikationspartner.</li>
        <li>Nutzer.</li>
        <li>Gewinnspiel- und Wettbewerbsteilnehmer.</li>
        <li>Geschäfts- und Vertragspartner.</li>
        <li>Kunden.</li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>
          Erbringung vertraglicher Leistungen und Erfüllung vertraglicher
          Pflichten.
        </li>
        <li>Kommunikation.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Direktmarketing.</li>
        <li>Reichweitenmessung.</li>
        <li>Tracking.</li>
        <li>Büro- und Organisationsverfahren.</li>
        <li>Zielgruppenbildung.</li>
        <li>Organisations- und Verwaltungsverfahren.</li>
        <li>Durchführung von Gewinnspielen und Wettbewerben.</li>
        <li>Feedback.</li>
        <li>Marketing.</li>
        <li>Profile mit nutzerbezogenen Informationen.</li>
        <li>
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>Informationstechnische Infrastruktur.</li>
        <li>Öffentlichkeitsarbeit.</li>
        <li>Absatzförderung.</li>
        <li>Geschäftsprozesse und betriebswirtschaftliche Verfahren.</li>
      </ul>
      <h2>Maßgebliche Rechtsgrundlagen</h2>
      <p>
        <b>Maßgebliche Rechtsgrundlagen nach der DSGVO:</b> Im Folgenden
        erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren
        Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur
        Kenntnis, dass neben den Regelungen der DSGVO nationale
        Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
        können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
        maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
      </p>
      <ul>
        <li>
          <b>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</b> - Die
          betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
          betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
          mehrere bestimmte Zwecke gegeben.
        </li>
        <li>
          <b>
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
            lit. b) DSGVO)
          </b>{" "}
          - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
          Vertragspartei die betroffene Person ist, oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
          betroffenen Person erfolgen.
        </li>
        <li>
          <b>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</b> - Die
          Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten der betroffenen
          Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
        </li>
      </ul>
      <p>
        <b>Nationale Datenschutzregelungen in Deutschland:</b> Zusätzlich zu den
        Datenschutzregelungen der DSGVO gelten nationale Regelungen zum
        Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum
        Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung
        (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
        Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
        Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
        personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
        Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
        einschließlich Profiling. Ferner können Landesdatenschutzgesetze der
        einzelnen Bundesländer zur Anwendung gelangen.
      </p>
      <p>
        <b>Hinweis auf Geltung DSGVO und Schweizer DSG:</b>
        Diese Datenschutzhinweise dienen sowohl der Informationserteilung nach
        dem Schweizer DSG als auch nach der Datenschutzgrundverordnung (DSGVO).
        Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der breiteren
        räumlichen Anwendung und Verständlichkeit die Begriffe der DSGVO
        verwendet werden. Insbesondere statt der im Schweizer DSG verwendeten
        Begriffe „Bearbeitung" von „Personendaten", "überwiegendes Interesse"
        und "besonders schützenswerte Personendaten" werden die in der DSGVO
        verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie
        "berechtigtes Interesse" und "besondere Kategorien von Daten" verwendet.
        Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung
        des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
      </p>
      <h2>Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
        Berücksichtigung des Stands der Technik, der Implementierungskosten und
        der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
        der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
        Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete
        technische und organisatorische Maßnahmen, um ein dem Risiko
        angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören
        insbesondere die Sicherung der Vertraulichkeit, Integrität und
        Verfügbarkeit von Daten durch Kontrolle des physischen und
        elektronischen Zugangs zu den Daten als auch des sie betreffenden
        Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit
        und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
        eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
        Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
        berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
        Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren
        entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und
        durch datenschutzfreundliche Voreinstellungen. Kürzung der IP-Adresse:
        Sofern IP-Adressen von uns oder von den eingesetzten Dienstleistern und
        Technologien verarbeitet werden und die Verarbeitung einer vollständigen
        IP-Adresse nicht erforderlich ist, wird die IP-Adresse gekürzt (auch als
        "IP-Masking" bezeichnet). Hierbei werden die letzten beiden Ziffern,
        bzw. der letzte Teil der IP-Adresse nach einem Punkt entfernt, bzw.
        durch Platzhalter ersetzt. Mit der Kürzung der IP-Adresse soll die
        Identifizierung einer Person anhand ihrer IP-Adresse verhindert oder
        wesentlich erschwert werden. Sicherung von Online-Verbindungen durch
        TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die Daten der Nutzer,
        die über unsere Online-Dienste übertragen werden, vor unerlaubten
        Zugriffen zu schützen, setzen wir auf die
        TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets Layer (SSL) und
        Transport Layer Security (TLS) sind die Eckpfeiler der sicheren
        Datenübertragung im Internet. Diese Technologien verschlüsseln die
        Informationen, die zwischen der Website oder App und dem Browser des
        Nutzers (oder zwischen zwei Servern) übertragen werden, wodurch die
        Daten vor unbefugtem Zugriff geschützt sind. TLS, als die
        weiterentwickelte und sicherere Version von SSL, gewährleistet, dass
        alle Datenübertragungen den höchsten Sicherheitsstandards entsprechen.
        Wenn eine Website durch ein SSL-/TLS-Zertifikat gesichert ist, wird dies
        durch die Anzeige von HTTPS in der URL signalisiert. Dies dient als ein
        Indikator für die Nutzer, dass ihre Daten sicher und verschlüsselt
        übertragen werden.
      </p>
      <h2>Übermittlung von personenbezogenen Daten</h2>
      <p>
        Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
        dass diese an andere Stellen, Unternehmen, rechtlich selbstständige
        Organisationseinheiten oder Personen übermittelt beziehungsweise ihnen
        gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z.
        B. mit IT-Aufgaben beauftragte Dienstleister gehören oder Anbieter von
        Diensten und Inhalten, die in eine Website eingebunden sind. In solchen
        Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere
        entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
        dienen, mit den Empfängern Ihrer Daten ab. Datenübermittlung innerhalb
        der Organisation: Datenübermittlung innerhalb der Unternehmensgruppe:
        Wir können personenbezogene Daten an andere Unternehmen innerhalb
        unserer Unternehmensgruppe übermitteln oder ihnen den Zugriff darauf
        gewähren. Sofern die Datenweitergabe zu administrativen Zwecken erfolgt,
        beruht sie auf unseren berechtigten unternehmerischen und
        betriebswirtschaftlichen Interessen oder erfolgt, sofern sie zur
        Erfüllung unserer vertragsbezogenen Verpflichtungen erforderlich ist
        beziehungsweise wenn eine Einwilligung der Betroffenen oder eine
        gesetzliche Erlaubnis vorliegt.
      </p>
      <h2>Internationale Datentransfers</h2>
      <p>
        Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
        (d. h., außerhalb der Europäischen Union (EU), des Europäischen
        Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
        Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
        Übermittlung von Daten an andere Personen, Stellen oder Unternehmen
        stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
        Sofern das Datenschutzniveau in dem Drittland mittels eines
        Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient dieser
        als Grundlage des Datentransfers. Im Übrigen erfolgen Datentransfers nur
        dann, wenn das Datenschutzniveau anderweitig gesichert ist, insbesondere
        durch Standardvertragsklauseln (Art. 46 Abs. 2 lit. c) DSGVO),
        ausdrückliche Einwilligung oder im Fall vertraglicher oder gesetzlich
        erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO). Im Übrigen teilen
        wir Ihnen die Grundlagen der Drittlandübermittlung bei den einzelnen
        Anbietern aus dem Drittland mit, wobei die Angemessenheitsbeschlüsse als
        Grundlagen vorrangig gelten. Informationen zu Drittlandtransfers und
        vorliegenden Angemessenheitsbeschlüssen können dem Informationsangebot
        der EU-Kommission entnommen werden:
        https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
        EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten
        „Data Privacy Framework" (DPF) hat die EU-Kommission das
        Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im
        Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher
        anerkannt. Die Liste der zertifizierten Unternehmen als auch weitere
        Informationen zu dem DPF können Sie der Website des Handelsministeriums
        der USA unter https://www.dataprivacyframework.gov/ (in Englisch)
        entnehmen. Wir informieren Sie im Rahmen der Datenschutzhinweise, welche
        von uns eingesetzten Diensteanbieter unter dem Data Privacy Framework
        zertifiziert sind.
      </p>
      <h2>Allgemeine Informationen zur Datenspeicherung und Löschung</h2>
      <p>
        Wir löschen personenbezogene Daten, die wir verarbeiten, gemäß den
        gesetzlichen Bestimmungen, sobald die zugrundeliegenden Einwilligungen
        widerrufen werden oder keine weiteren rechtlichen Grundlagen für die
        Verarbeitung bestehen. Dies betrifft Fälle, in denen der ursprüngliche
        Verarbeitungszweck entfällt oder die Daten nicht mehr benötigt werden.
        Ausnahmen von dieser Regelung bestehen, wenn gesetzliche Pflichten oder
        besondere Interessen eine längere Aufbewahrung oder Archivierung der
        Daten erfordern. Insbesondere müssen Daten, die aus handels- oder
        steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
        Speicherung notwendig ist zur Rechtsverfolgung oder zum Schutz der
        Rechte anderer natürlicher oder juristischer Personen, entsprechend
        archiviert werden. Unsere Datenschutzhinweise enthalten zusätzliche
        Informationen zur Aufbewahrung und Löschung von Daten, die speziell für
        bestimmte Verarbeitungsprozesse gelten. Bei mehreren Angaben zur
        Aufbewahrungsdauer oder Löschungsfristen eines Datums, ist stets die
        längste Frist maßgeblich. Beginnt eine Frist nicht ausdrücklich zu einem
        bestimmten Datum und beträgt sie mindestens ein Jahr, so startet sie
        automatisch am Ende des Kalenderjahres, in dem das fristauslösende
        Ereignis eingetreten ist. Im Fall laufender Vertragsverhältnisse, in
        deren Rahmen Daten gespeichert werden, ist das fristauslösende Ereignis
        der Zeitpunkt des Wirksamwerdens der Kündigung oder sonstige Beendigung
        des Rechtsverhältnisses. Daten, die nicht mehr für den ursprünglich
        vorgesehenen Zweck, sondern aufgrund gesetzlicher Vorgaben oder anderer
        Gründe aufbewahrt werden, verarbeiten wir ausschließlich zu den Gründen,
        die ihre Aufbewahrung rechtfertigen.
      </p>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <p>
        <b>• Aufbewahrung und Löschung von Daten:</b> Die folgenden allgemeinen
        Fristen gelten für die Aufbewahrung und Archivierung nach deutschem
        Recht:{" "}
      </p>
      <ul>
        <li>
          o 10 Jahre - Aufbewahrungsfrist für Bücher und Aufzeichnungen,
          Jahresabschlüsse, Inventare, Lageberichte, Eröffnungsbilanz sowie die
          zu ihrem Verständnis erforderlichen Arbeitsanweisungen und sonstigen
          Organisationsunterlagen, Buchungsbelege und Rechnungen (§ 147 Abs. 3
          i. V. m. Abs. 1 Nr. 1, 4 und 4a AO, § 14b Abs. 1 UStG, § 257 Abs. 1
          Nr. 1 u. 4, Abs. 4 HGB).
        </li>
        <li>
          o 6 Jahre - Übrige Geschäftsunterlagen: empfangene Handels- oder
          Geschäftsbriefe, Wiedergaben der abgesandten Handels- oder
          Geschäftsbriefe, sonstige Unterlagen, soweit sie für die Besteuerung
          von Bedeutung sind, z. B. Stundenlohnzettel, Betriebsabrechnungsbögen,
          Kalkulationsunterlagen, Preisauszeichnungen, aber auch
          Lohnabrechnungsunterlagen, soweit sie nicht bereits Buchungsbelege
          sind und Kassenstreifen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 2, 3, 5 AO,
          § 257 Abs. 1 Nr. 2 u. 3, Abs. 4 HGB).
        </li>
        <li>
          o 3 Jahre - Daten, die erforderlich sind, um potenzielle
          Gewährleistungs- und Schadensersatzansprüche oder ähnliche
          vertragliche Ansprüche und Rechte zu berücksichtigen sowie damit
          verbundene Anfragen zu bearbeiten, basierend auf früheren
          Geschäftserfahrungen und üblichen Branchenpraktiken, werden für die
          Dauer der regulären gesetzlichen Verjährungsfrist von drei Jahren
          gespeichert (§§ 195, 199 BGB).
        </li>
      </ul>
      <h2>Rechte der betroffenen Personen</h2>
      <p>
        Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
        Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere
        aus Art. 15 bis 21 DSGVO ergeben:
      </p>
      <ul>
        <li>
          <b>
            <b>Widerspruchsrecht:</b> Sie haben das Recht, aus Gründen, die sich
            aus Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten, die
            aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch
            einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
            Profiling. Werden die Sie betreffenden personenbezogenen Daten
            verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht,
            jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht.
          </b>
        </li>
        <li>
          <b>Widerrufsrecht bei Einwilligungen:</b> Sie haben das Recht,
          erteilte Einwilligungen jederzeit zu widerrufen.
        </li>
        <li>
          <b>Auskunftsrecht:</b> Sie haben das Recht, eine Bestätigung darüber
          zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft
          über diese Daten sowie auf weitere Informationen und Kopie der Daten
          entsprechend den gesetzlichen Vorgaben.
        </li>
        <li>
          <b>Recht auf Berichtigung:</b> Sie haben entsprechend den gesetzlichen
          Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten
          oder die Berichtigung der Sie betreffenden unrichtigen Daten zu
          verlangen.
        </li>
        <li>
          <b>Recht auf Löschung und Einschränkung der Verarbeitung:</b> Sie
          haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen,
          dass Sie betreffende Daten unverzüglich gelöscht werden, bzw.
          alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung
          der Verarbeitung der Daten zu verlangen.
        </li>
        <li>
          <b>Recht auf Datenübertragbarkeit:</b> Sie haben das Recht, Sie
          betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
          gesetzlichen Vorgaben in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
          anderen Verantwortlichen zu fordern.
        </li>
        <li>
          <b>Beschwerde bei Aufsichtsbehörde:</b> Sie haben unbeschadet eines
          anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
          das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in
          dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres
          Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der
          Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
        </li>
      </ul>
      <h2>Geschäftliche Leistungen</h2>
      <p>
        Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z. B.
        Kunden und Interessenten (zusammenfassend als „Vertragspartner"
        bezeichnet), im Rahmen von vertraglichen und vergleichbaren
        Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Hinblick
        auf die Kommunikation mit den Vertragspartnern (oder vorvertraglich),
        etwa zur Beantwortung von Anfragen. Wir verwenden diese Daten, um unsere
        vertraglichen Verpflichtungen zu erfüllen. Dazu gehören insbesondere die
        Pflichten zur Erbringung der vereinbarten Leistungen, etwaige
        Aktualisierungspflichten und Abhilfe bei Gewährleistungs- und sonstigen
        Leistungsstörungen. Darüber hinaus verwenden wir die Daten zur Wahrung
        unserer Rechte und zum Zwecke der mit diesen Pflichten verbundenen
        Verwaltungsaufgaben sowie der Unternehmensorganisation. Zudem
        verarbeiten wir die Daten auf Grundlage unserer berechtigten Interessen
        sowohl an einer ordnungsgemäßen und betriebswirtschaftlichen
        Geschäftsführung als auch an Sicherheitsmaßnahmen zum Schutz unserer
        Vertragspartner und unseres Geschäftsbetriebs vor Missbrauch, Gefährdung
        ihrer Daten, Geheimnisse, Informationen und Rechte (z. B. zur
        Beteiligung von Telekommunikations-, Transport- und sonstigen
        Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
        Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden
        Rechts geben wir die Daten von Vertragspartnern nur insoweit an Dritte
        weiter, als dies für die vorgenannten Zwecke oder zur Erfüllung
        gesetzlicher Pflichten erforderlich ist. Über weitere Formen der
        Verarbeitung, etwa zu Marketingzwecken, werden die Vertragspartner im
        Rahmen dieser Datenschutzerklärung informiert. Welche Daten für die
        vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern
        vor oder im Rahmen der Datenerhebung, z. B. in Onlineformularen, durch
        besondere Kennzeichnung (z. B. Farben) bzw. Symbole (z. B. Sternchen o.
        Ä.), oder persönlich mit. Wir löschen die Daten nach Ablauf gesetzlicher
        Gewährleistungs- und vergleichbarer Pflichten, d. h. grundsätzlich nach
        vier Jahren, es sei denn, dass die Daten in einem Kundenkonto
        gespeichert werden, z. B., solange sie aus gesetzlichen Gründen der
        Archivierung aufbewahrt werden müssen (etwa für Steuerzwecke im
        Regelfall zehn Jahre). Daten, die uns im Rahmen eines Auftrags durch den
        Vertragspartner offengelegt wurden, löschen wir entsprechend den
        Vorgaben und grundsätzlich nach Ende des Auftrags.
        <ul>
          <li>
            <b>Verarbeitete Datenarten:</b> Bestandsdaten (z. B. der
            vollständige Name, Wohnadresse, Kontaktinformationen, Kundennummer,
            etc.); Zahlungsdaten (z. B. Bankverbindungen, Rechnungen,
            Zahlungshistorie); Kontaktdaten (z. B. Post- und E-Mail-Adressen
            oder Telefonnummern); Vertragsdaten (z. B. Vertragsgegenstand,
            Laufzeit, Kundenkategorie); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz,
            verwendete Gerätetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen).
          </li>
          <li>
            <b>Betroffene Personen:</b> Leistungsempfänger und Auftraggeber;
            Interessenten. Geschäfts- und Vertragspartner.
          </li>
          <li>
            <b>Zwecke der Verarbeitung:</b> Erbringung vertraglicher Leistungen
            und Erfüllung vertraglicher Pflichten; Sicherheitsmaßnahmen;
            Kommunikation; Büro- und Organisationsverfahren; Organisations- und
            Verwaltungsverfahren. Geschäftsprozesse und betriebswirtschaftliche
            Verfahren.
          </li>
          <li>
            <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
            Abschnitt "Allgemeine Informationen zur Datenspeicherung und
            Löschung".
          </li>
          <li>
            <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche
            Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO). Berechtigte
            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
      </p>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>Onlineshop, Bestellformulare, E-Commerce und Auslieferung:</b> Wir
          verarbeiten die Daten unserer Kunden, um ihnen die Auswahl, den
          Erwerb, bzw. die Bestellung der gewählten Produkte, Waren sowie
          verbundener Leistungen, als auch deren Bezahlung und Zustellung, bzw.
          Ausführung zu ermöglichen. Sofern für die Ausführung einer Bestellung
          erforderlich, setzen wir Dienstleister, insbesondere Post-,
          Speditions- und Versandunternehmen ein, um die Lieferung, bzw.
          Ausführung gegenüber unseren Kunden durchzuführen. Für die Abwicklung
          der Zahlungsvorgänge nehmen wir die Dienste von Banken und
          Zahlungsdienstleistern in Anspruch. Die erforderlichen Angaben sind
          als solche im Rahmen des Bestell- bzw. vergleichbaren Erwerbsvorgangs
          gekennzeichnet und umfassen die zur Auslieferung, bzw.
          Zurverfügungstellung und Abrechnung benötigten Angaben sowie
          Kontaktinformationen, um etwaige Rücksprache halten zu können;
          Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art.
          6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <h2>Geschäftsprozesse und -verfahren</h2>
      <p>
        Personenbezogene Daten von Leistungsempfängern und Auftraggebern –
        darunter Kunden, Klienten oder in speziellen Fällen Mandanten, Patienten
        oder Geschäftspartner sowie weitere Dritte – werden im Rahmen
        vertraglicher sowie vergleichbarer Rechtsverhältnisse und
        vorvertraglicher Maßnahmen wie der Anbahnung von Geschäftsbeziehungen
        verarbeitet. Diese Datenverarbeitung unterstützt und erleichtert
        betriebswirtschaftliche Abläufe in Bereichen wie Kundenmanagement,
        Vertrieb, Zahlungsverkehr, Buchhaltung und Projektmanagement. Die
        erfassten Daten dienen dazu, vertragliche Verpflichtungen zu erfüllen
        und betriebliche Prozesse effizient zu gestalten. Hierzu gehört die
        Abwicklung von Geschäftstransaktionen, das Management von
        Kundenbeziehungen, die Optimierung von Vertriebsstrategien sowie die
        Gewährleistung interner Rechnungs- und Finanzprozesse. Zusätzlich
        unterstützen die Daten die Wahrung der Rechte des Verantwortlichen und
        fördern Verwaltungsaufgaben sowie die Organisation des Unternehmens.
        Personenbezogene Daten können an Dritte weitergegeben werden, sofern
        dies zur Erfüllung der genannten Zwecke oder gesetzlicher
        Verpflichtungen notwendig - Dieser Textbereich muss mit einer Premium
        Lizenz freischaltet werden. - premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Bestandsdaten (z. B. der vollständige
          Name, Wohnadresse, Kontaktinformationen, Kundennummer, - Dieser
          Textbereich muss mit einer Premium Lizenz freischaltet werden. -
          premiumtext premiumtext premiumtext ); Zahlungsdaten (z. B.
          Bankverbindungen, Rechnungen, Zahlungshistorie - Dieser Textbereich
          muss mit einer Premium Lizenz freischaltet werden. - premiumtext
          premiumtext ); Kontaktdaten (z. B. Post- und E-Mail-Adressen oder -
          Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
          - premiumtext premiumtext premiumtext ); Inhaltsdaten (z. B. textliche
          oder bildliche Nachrichten und Beiträge sowie die sie betreffenden
          Informationen, wie z. B. Angaben zur Autorenschaft - Dieser
          Textbereich muss mit einer Premium Lizenz freischaltet werden. -
          premiumtext premiumtext premiumtext premiumtext premiumtext
          premiumtext ); Vertragsdaten (z. B. Vertragsgegenstand, Laufzeit,
          Kundenkategorie - Dieser Textbereich muss mit einer Premium Lizenz
          freischaltet werden. - premiumtext premiumtext ); Nutzungsdaten (z. B.
          Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
          -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
          mit - Dieser Textbereich muss mit einer Premium Lizenz freischaltet
          werden. - premiumtext premiumtext premiumtext premiumtext premiumtext
          ). Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
          Zeitangaben, Identifikationsnummern, beteiligte - Dieser Textbereich
          muss mit einer Premium Lizenz freischaltet werden. - premiumtext
          premiumtext premiumtext ).
        </li>
        <li>
          <b>Betroffene Personen:</b> Leistungsempfänger und Auftraggeber;
          Interessenten; Kommunikationspartner; Geschäfts- und Vertragspartner.
          Kunden.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Erbringung vertraglicher Leistungen
          und Erfüllung vertraglicher Pflichten; Büro- und
          Organisationsverfahren; Geschäftsprozesse und betriebswirtschaftliche
          Verfahren; Sicherheitsmaßnahmen. Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung".
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche
          Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen
          (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>Kundenkonto:</b> Kunden können innerhalb unseres Onlineangebotes
          ein Konto anlegen (z. B. Kunden- bzw. Nutzerkonto, kurz
          "Kundenkonto"). Falls die Registrierung eines Kundenkontos
          erforderlich ist, werden Kunden hierauf ebenso hingewiesen wie auf die
          für die Registrierung erforderlichen Angaben. Die Kundenkonten sind
          nicht öffentlich und können von Suchmaschinen nicht indexiert werden.
          Im Rahmen der Registrierung sowie anschließender Anmeldungen und
          Nutzungen des Kundenkontos speichern wir die IP-Adressen der Kunden
          nebst den Zugriffszeitpunkten, um die Registrierung nachweisen und
          etwaigem Missbrauch des Kundenkontos vorbeugen zu können. Wurde das
          Kundenkonto gekündigt, werden die Daten des Kundenkontos nach dem
          Kündigungszeitpunkt gelöscht, sofern sie nicht für andere Zwecke als
          die Bereitstellung im Kundenkonto aufbewahrt werden oder aus
          rechtlichen Gründen aufbewahrt werden müssen (z. B. interne
          Speicherung von Kundendaten, Bestellvorgängen oder Rechnungen). Es
          liegt in der Verantwortung der Kunden, ihre Daten bei Kündigung des
          Kundenkontos zu sichern; <b>Rechtsgrundlagen: </b> Vertragserfüllung
          und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>Bereitstellung des Onlineangebots und Webhosting</h2>
      <p>
        Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
        Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
        IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen
        unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu
        übermitteln.
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Nutzungsdaten (z. B. Seitenaufrufe und
          Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete
          Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und
          Funktionen); Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
          Personen); Protokolldaten (z. B. Logfiles betreffend Logins oder den
          Abruf von Daten oder Zugriffszeiten.); Inhaltsdaten (z. B. textliche
          oder bildliche Nachrichten und Beiträge sowie die sie betreffenden
          Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt der
          Erstellung).
        </li>
        <li>
          <b>Betroffene Personen:</b> Nutzer (z. B. Webseitenbesucher, Nutzer
          von Onlinediensten).
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Bereitstellung unseres Onlineangebotes
          und Nutzerfreundlichkeit; Informationstechnische Infrastruktur
          (Betrieb und Bereitstellung von Informationssystemen und technischen
          Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen.
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung".
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO).
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>Bereitstellung Onlineangebot auf gemietetem Speicherplatz:</b> Für
          die Bereitstellung unseres Onlineangebotes nutzen wir Speicherplatz,
          Rechenkapazität und Software, die wir von einem entsprechenden
          Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig
          beziehen; <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO).
        </li>
        <li>
          <b>Erhebung von Zugriffsdaten und Logfiles:</b> Der Zugriff auf unser
          Onlineangebot wird in Form von sogenannten "Server-Logfiles"
          protokolliert. Zu den Serverlogfiles können die Adresse und der Name
          der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
          übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
          nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
          besuchte Seite) und im Regelfall IP-Adressen und der anfragende
          Provider gehören. Die Serverlogfiles können zum einen zu
          Sicherheitszwecken eingesetzt werden, z. B. um eine Überlastung der
          Server zu vermeiden (insbesondere im Fall von missbräuchlichen
          Angriffen, sogenannten DDoS-Attacken), und zum anderen, um die
          Auslastung der Server und ihre Stabilität sicherzustellen;{" "}
          <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO). <b>Löschung von Daten:</b> Logfile-Informationen
          werden für die Dauer von maximal 30 Tagen gespeichert und danach
          gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu
          Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
          jeweiligen Vorfalls von der Löschung ausgenommen.
        </li>
        <li>
          <b>E-Mail-Versand und -Hosting:</b> Die von uns in Anspruch genommenen
          Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang
          sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die
          Adressen der Empfänger sowie Absender als auch weitere Informationen
          betreffend den E-Mailversand (z. B. die beteiligten Provider) sowie
          die Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten
          können ferner zu Zwecken der Erkennung von SPAM verarbeitet werden.
          Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich
          nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar
          auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes
          Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den
          Servern, von denen sie abgesendet und empfangen werden. Wir können
          daher für den Übertragungsweg der E-Mails zwischen dem Absender und
          dem Empfang auf unserem Server keine Verantwortung übernehmen;{" "}
          <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO).
        </li>
      </ul>
      <h2>Einsatz von Cookies</h2>
      <p>
        Cookies sind kleine Textdateien bzw. sonstige Speichervermerke, die
        Informationen auf Endgeräten speichern und aus ihnen auslesen. Zum
        Beispiel, um den Log-in-Status in einem Nutzerkonto, einen
        Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
        verwendete Funktionen eines Onlineangebots zu speichern. Cookies können
        ferner in Bezug auf unterschiedliche Anliegen Einsatz finden, etwa zu
        Zwecken der Funktionsfähigkeit, der Sicherheit und des Komforts von
        Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.
      </p>
      <p><b>Hinweise zur Einwilligung:</b> Wir setzen Cookies im Einklang mit den
      gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine
      vorhergehende Einwilligung ein, es sei denn, sie ist laut Gesetzeslage
      nicht gefordert. Eine Erlaubnis ist insbesondere nicht notwendig, wenn das
      Speichern und das Auslesen der Informationen, also auch von Cookies,
      unbedingt erforderlich sind, um den Nutzern einen von ihnen ausdrücklich
      gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu
      stellen. Die widerrufliche Einwilligung wird ihnen gegenüber deutlich
      kommuniziert und enthält die Informationen zur jeweiligen Cookie-Nutzung.</p>
      <p>
        <b>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:</b> Auf welcher
        datenschutzrechtlichen Grundlage wir die personenbezogenen Daten der
        Nutzer mithilfe von Cookies verarbeiten, hängt davon ab, ob wir sie um
        eine Einwilligung bitten. Falls die Nutzer akzeptieren, ist die
        Rechtsgrundlage der Verwertung ihrer Daten die erklärte Einwilligung.
        Andernfalls werden die mithilfe von Cookies verwerteten Daten auf
        Grundlage unserer berechtigten Interessen (z. B. an einem
        betriebswirtschaftlichen Betrieb unseres Onlineangebots und der
        Verbesserung seiner Nutzbarkeit) verarbeitet oder, falls dies im Rahmen
        der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz
        von Cookies erforderlich ist, um unseren vertraglichen Verpflichtungen
        nachzukommen. Zu welchen Zwecken die Cookies von uns verwertet werden,
        darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen
        von unseren Einwilligungs- und Verarbeitungsprozessen auf.
      </p>
      <p>
        <b>Speicherdauer: </b>Im Hinblick auf die Speicherdauer werden die
        folgenden Arten von Cookies unterschieden:
      </p>
      <ul>
        <li>
          <b>Temporäre Cookies (auch: Session- oder Sitzungscookies):</b>{" "}
          Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
          Onlineangebot verlassen und sein Endgerät (z. B. Browser oder mobile
          Applikation) geschlossen hat.
        </li>
        <li>
          <b>Permanente Cookies:</b> Permanente Cookies bleiben auch nach dem
          Schließen des Endgeräts gespeichert. So können beispielsweise der
          Log-in-Status gespeichert und bevorzugte Inhalte direkt angezeigt
          werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die
          mithilfe von Cookies erhobenen Nutzerdaten zur Reichweitenmessung
          Verwendung finden. Sofern wir Nutzern keine expliziten Angaben zur Art
          und Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung
          der Einwilligung), sollten sie davon ausgehen, dass diese permanent
          sind und die Speicherdauer bis zu zwei Jahre betragen kann.
        </li>
      </ul>
      <p>
        <b>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out):</b>{" "}
        Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
        widerrufen und zudem einen Widerspruch gegen die Verarbeitung
        entsprechend den gesetzlichen Vorgaben, auch mittels der
        Privatsphäre-Einstellungen ihres Browsers, erklären.
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, beteiligte Personen).
        </li>
        <li>
          <b>Betroffene Personen:</b> Nutzer (z. B. Webseitenbesucher, Nutzer
          von Onlinediensten).
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:</b>{" "}
          Wir setzen eine Einwilligungs-Management-Lösung ein, bei der die
          Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im
          Rahmen der Einwilligungs-Management-Lösung genannten Verfahren und
          Anbietern eingeholt wird. Dieses Verfahren dient der Einholung,
          Protokollierung, Verwaltung und dem Widerruf von Einwilligungen,
          insbesondere bezogen auf den Einsatz von Cookies und vergleichbaren
          Technologien, die zur Speicherung, zum Auslesen und zur Verarbeitung
          von Informationen auf den Endgeräten der Nutzer eingesetzt werden. Im
          Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für die
          Nutzung von Cookies und die damit verbundenen Verarbeitungen von
          Informationen, einschließlich der im
          Einwilligungs-Management-Verfahren genannten spezifischen
          Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem die
          Möglichkeit, ihre Einwilligungen zu verwalten und zu widerrufen. Die
          Einwilligungserklärungen werden gespeichert, um eine erneute Abfrage
          zu vermeiden und den Nachweis der Einwilligung gemäß der gesetzlichen
          Anforderungen führen zu können. Die Speicherung erfolgt serverseitig
          und/oder in einem Cookie (sogenanntes Opt-In-Cookie) oder mittels
          vergleichbarer Technologien, um die Einwilligung einem spezifischen
          Nutzer oder dessen Gerät zuordnen zu können. Sofern keine spezifischen
          Angaben zu den Anbietern von Einwilligungs-Management-Diensten
          vorliegen, gelten folgende allgemeine Hinweise: Die Dauer der
          Speicherung der Einwilligung beträgt bis zu zwei Jahre. Dabei wird ein
          pseudonymer Nutzer-Identifikator erstellt, der zusammen mit dem
          Zeitpunkt der Einwilligung, den Angaben zum Umfang der Einwilligung
          (z. B. betreffende Kategorien von Cookies und/oder Diensteanbieter)
          sowie Informationen über den Browser, das System und das verwendete
          Endgerät gespeichert wird; Rechtsgrundlagen: Einwilligung (Art. 6 Abs.
          1 S. 1 lit. a) DSGVO).
        </li>
      </ul>
      <h2>Registrierung, Anmeldung und Nutzerkonto</h2>
      <p>
        Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung
        werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu
        Zwecken der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher
        Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten gehören
        insbesondere die Login-Informationen (Nutzername, Passwort sowie eine
        E-Mail-Adresse). <br />
        Im Rahmen der Inanspruchnahme unserer Registrierungs- und
        Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die
        IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
        Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
        auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger
        unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
        grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer
        Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung
        hierzu.
        <br />
        Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant
        sind, wie z. B. technische Änderungen, per E-Mail informiert werden.
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Bestandsdaten (z. B. der vollständige
          Name, Wohnadresse, Kontaktinformationen, Kundennummer, etc.);
          Kontaktdaten (z. B. Post- und E-Mail-Adressen oder Telefonnummern);
          Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge
          sowie die sie betreffenden Informationen, wie z. B. Angaben zur
          Autorenschaft oder Zeitpunkt der Erstellung); Nutzungsdaten (z. B.
          Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
          -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
          mit Inhalten und Funktionen). Protokolldaten (z. B. Logfiles
          betreffend Logins oder den Abruf von Daten oder Zugriffszeiten.).
        </li>
        <li>
          <b>Betroffene Personen:</b> Nutzer (z. B. Webseitenbesucher, Nutzer
          von Onlinediensten).
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Erbringung vertraglicher Leistungen
          und Erfüllung vertraglicher Pflichten; Sicherheitsmaßnahmen;
          Organisations- und Verwaltungsverfahren. Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung". Löschung nach Kündigung.
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche
          Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen
          (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>Registrierung mit Pseudonymen:</b> Nutzer dürfen statt Klarnamen
          Pseudonyme als Nutzernamen verwenden; <b>Rechtsgrundlagen:</b>{" "}
          Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
          lit. b) DSGVO).
        </li>
        <li>
          <b>Einstellung der Sichtbarkeit von Profilen:</b> Die Nutzer können
          mittels Einstellungen bestimmen, in welchem Umfang ihre Profile für
          die Öffentlichkeit oder nur für bestimmte Personengruppen sichtbar,
          bzw. zugänglich sind; <b>Rechtsgrundlagen:</b> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
        <li>
          <b>Löschung von Daten nach Kündigung:</b> Wenn Nutzer ihr Nutzerkonto
          gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto,
          vorbehaltlich einer gesetzlichen Erlaubnis, Pflicht oder Einwilligung
          der Nutzer, gelöscht; <b>Rechtsgrundlagen:</b> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <h2>Kontakt- und Anfrageverwaltung</h2>
      <p>
        Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular,
        E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender
        Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden
        Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen
        und etwaiger angefragter Maßnahmen erforderlich ist.
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Bestandsdaten (z. B. der vollständige
          Name, Wohnadresse, Kontaktinformationen, Kundennummer, etc.);
          Kontaktdaten (z. B. Post- und E-Mail-Adressen oder Telefonnummern);
          Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge
          sowie die sie betreffenden Informationen, wie z. B. Angaben zur
          Autorenschaft oder Zeitpunkt der Erstellung); Nutzungsdaten (z. B.
          Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
          -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
          mit Inhalten und Funktionen). Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, beteiligte Personen).
        </li>
        <li>
          <b>Betroffene Personen:</b> Kommunikationspartner.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Kommunikation; Organisations- und
          Verwaltungsverfahren; Feedback (z. B. Sammeln von Feedback via
          Online-Formular). Bereitstellung unseres Onlineangebotes und
          Nutzerfreundlichkeit.
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung".
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen (Art. 6
          Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>Kontaktformular:</b> Bei Kontaktaufnahme über unser
          Kontaktformular, per E-Mail oder anderen Kommunikationswegen,
          verarbeiten wir die uns übermittelten personenbezogenen Daten zur
          Beantwortung und Bearbeitung des jeweiligen Anliegens. Dies umfasst in
          der Regel Angaben wie Name, Kontaktinformationen und gegebenenfalls
          weitere Informationen, die uns mitgeteilt werden und zur angemessenen
          Bearbeitung erforderlich sind. Wir nutzen diese Daten ausschließlich
          für den angegebenen Zweck der Kontaktaufnahme und Kommunikation;{" "}
          <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche
          Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen
          (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>Push-Nachrichten</h2>
      <p>
        Mit der Zustimmung der Nutzer, können wir den Nutzern so genannte
        "Push-Benachrichtigungen" zusenden. Dabei handelt es sich um
        Nachrichten, die auf den Bildschirmen, Endgeräten oder in Browsern der
        Nutzer angezeigt werden, auch wenn unser Onlinedienst gerade nicht aktiv
        genutzt wird. <br />
        Um sich für die Push-Nachrichten anzumelden, müssen Nutzer die Abfrage
        ihres Browsers bzw. Endgerätes zum Erhalt der Push-Nachrichten
        bestätigen. Dieser Zustimmungsprozess wird dokumentiert und gespeichert.
        Die Speicherung ist erforderlich, um zu erkennen, ob Nutzer dem Empfang
        der Push-Nachrichten zugestimmt haben sowie um die Zustimmung nachweisen
        zu können. Zu diesen Zwecken wird ein pseudonymer Identifikator des
        Browsers (sog. "Push-Token") oder die Geräte-ID eines Endgerätes
        gespeichert. <br />
        Die Push-Nachrichten können zum einen für die Erfüllung von
        vertraglichen Pflichten erforderlich sein (z. B. für die Nutzung unseres
        Onlineangebotes relevante technische und organisatorische Informationen)
        und - Dieser Textbereich muss mit einer Premium Lizenz freischaltet
        werden. - premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
        premiumtext premiumtext premiumtext premiumtext
      </p>
      <h3>Inhalte: </h3>
      <p>- Informationen zu unserem Bartwettbewerb (Start, Ende, Gewinner).</p>
      <p>
        - Informationen über Änderungen unserer Applikation sowie ihrer
        Funktionen.
      </p>
      <p>
        Unsere Push-Nachrichten können Sie in Ihrem Profil in dem Bereich
        "Datenschutz & Sicherheit" deaktivieren.
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Nutzungsdaten (z. B. Seitenaufrufe und
          Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete
          Gerätetypen und Betriebssysteme, Interaktionen mit - Dieser
          Textbereich muss mit einer Premium Lizenz freigeschaltet werden. -
          premiumtext premiumtext premiumtext premiumtext premiumtext ). Meta-,
          Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, beteiligte - Dieser Textbereich muss mit einer
          Premium Lizenz freigeschaltet werden. - premiumtext premiumtext
          premiumtext ).
        </li>
        <li>
          <b>Betroffene Personen:</b> Kommunikationspartner.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Kommunikation. Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung". Löschung nach Kündigung.
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
          DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>Newsletter und elektronische Benachrichtigungen</h2>
      <p>
        Wir versenden Newsletter, E-Mails und weitere elektronische
        Benachrichtigungen (nachfolgend „Newsletter") ausschließlich mit der
        Einwilligung der Empfänger oder aufgrund einer gesetzlichen Grundlage.
        Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte genannt
        werden, sind diese Inhalte für die Einwilligung der Nutzer maßgeblich.
        Für die Anmeldung zu unserem Newsletter ist normalerweise die Angabe
        Ihrer E-Mail-Adresse ausreichend. Um Ihnen jedoch einen personalisierten
        Service bieten zu können, bitten wir gegebenenfalls um die Angabe Ihres
        Namens für eine persönliche Ansprache im Newsletter oder um weitere
        Informationen, falls diese für den Zweck des Newsletters notwendig sind.
        Löschung und Einschränkung der Verarbeitung: Wir können die
        ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer
        berechtigten Interessen speichern, bevor wir sie löschen, um eine
        ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung
        dieser Daten wird auf den Zweck einer potenziellen Abwehr von Ansprüchen
        beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich,
        sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt
        wird. Im Fall von Pflichten zur dauerhaften Beachtung von Widersprüchen
        behalten wir uns die Speicherung der E-Mail-Adresse alleine zu diesem
        Zweck in einer Sperrliste (sogenannte „Blocklist") vor. Die
        Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer
        berechtigten Interessen zum Zweck des Nachweises seines ordnungsgemäßen
        Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
        beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen
        an einem effizienten und sicheren Versandsystem.
      </p>
      <h3>Inhalte: </h3>
      <p>Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Bestandsdaten (z. B. der vollständige
          Name, Wohnadresse, Kontaktinformationen, Kundennummer, etc.);
          Kontaktdaten (z. B. Post- und E-Mail-Adressen oder Telefonnummern);
          Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
          Zeitangaben, Identifikationsnummern, beteiligte Personen).
          Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade,
          Nutzungsintensität und -frequenz, verwendete Gerätetypen und
          Betriebssysteme, Interaktionen mit Inhalten und Funktionen).
        </li>
        <li>
          <b>Betroffene Personen:</b> Kommunikationspartner.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Direktmarketing (z. B. per E-Mail oder
          postalisch).
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> 3 Jahre - Vertragliche Ansprüche
          (AT) (Daten, die erforderlich sind, um potenzielle Gewährleistungs-
          und Schadensersatzansprüche oder ähnliche vertragliche Ansprüche und
          Rechte zu berücksichtigen sowie damit verbundene Anfragen zu
          bearbeiten, basierend auf früheren Geschäftserfahrungen und üblichen
          Branchenpraktiken, werden für die Dauer der regulären gesetzlichen
          Verjährungsfrist von drei Jahren gespeichert (§§ 1478, 1480 ABGB). 10
          Jahre - Vertragliche Ansprüche (CH) (Daten, die zur Berücksichtigung
          potenzieller Schadenersatzansprüche oder ähnlicher vertraglicher
          Ansprüche und Rechte notwendig sind, sowie für die Bearbeitung damit
          verbundener Anfragen, basierend auf früheren Geschäftserfahrungen und
          den üblichen Branchenpraktiken, werden für den Zeitraum der
          gesetzlichen Verjährungsfrist von zehn Jahren gespeichert, es sei
          denn, eine kürzere Frist von 5 Jahren ist maßgeblich, die in
          bestimmten Fällen einschlägig ist (Art. 127, 130 OR)).
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
          DSGVO).
        </li>
        <li>
          <b>Widerspruchsmöglichkeit (Opt-Out):</b> Sie können den Empfang
          unseres Newsletters jederzeit kündigen, d. .h. Ihre Einwilligungen
          widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link zur
          Kündigung des Newsletters finden Sie entweder am Ende eines jeden
          Newsletters oder können sonst eine der oben angegebenen
          Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>Messung von Öffnungs- und Klickraten:</b> Die Newsletter enthalten
          einen sogenannten „Web Beacons", d. h. eine pixelgroße Datei, die beim
          Öffnen des Newsletters von unserem bzw. dessen Server, sofern wir
          einen Versanddienstleister einsetzen, abgerufen wird. Im Rahmen dieses
          Abrufs werden zunächst sowohl technische Informationen, wie
          beispielsweise Angaben zum Browser und Ihrem System, als auch Ihre
          IP-Adresse und der Zeitpunkt des Abrufs erhoben. Diese Informationen
          werden zur technischen Verbesserung unseres Newsletters anhand der
          technischen Daten oder der Zielgruppen und ihres Leseverhaltens auf
          Basis ihrer Abruforte (die mithilfe der IP-Adresse bestimmbar sind)
          oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls
          die Feststellung, ob und wann die Newsletter geöffnet und welche Links
          angeklickt werden. Die Informationen werden den einzelnen
          Newsletterempfängern zugeordnet und in deren Profilen bis zur Löschung
          gespeichert. Die Auswertungen dienen dazu, die Lesegewohnheiten
          unserer Nutzer zu erkennen und unsere Inhalte an sie anzupassen oder
          unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu
          versenden.<b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1
          lit. a) DSGVO).
        </li>
      </ul>
      <h2>Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>
      <p>
        {" "}
        Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
        Kommunikation, die über diverse Kanäle, wie z. B. E-Mail, Telefon, Post
        oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
        <br />
        Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu
        widerrufen oder der werblichen Kommunikation jederzeit zu widersprechen.{" "}
        <br />
        Nach Widerruf oder Widerspruch speichern wir die zum Nachweis der
        bisherigen Berechtigung erforderlichen Daten zur Kontaktaufnahme oder
        Zusendung bis zu drei Jahre nach Ablauf des Jahres des Widerrufs oder
        Widerspruchs auf der Grundlage unserer berechtigten Interessen. Die
        Verarbeitung dieser Daten ist auf den Zweck einer möglichen Abwehr von
        Ansprüchen beschränkt. Auf der Grundlage des berechtigten Interesses,
        den Widerruf bzw. Widerspruch der Nutzer dauerhaft zu beachten,
        speichern wir ferner die zur Vermeidung einer erneuten Kontaktaufnahme
        erforderlichen Daten (z. B. je nach Kommunikationskanal die
        E-Mail-Adresse, Telefonnummer, Name).
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Bestandsdaten (z. B. der vollständige
          Name, Wohnadresse, Kontaktinformationen, Kundennummer, etc.);
          Kontaktdaten (z. B. Post- und E-Mail-Adressen oder Telefonnummern).
          Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge
          sowie die sie betreffenden Informationen, wie z. B. Angaben zur
          Autorenschaft oder Zeitpunkt der Erstellung).
        </li>
        <li>
          <b>Betroffene Personen:</b> Kommunikationspartner.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Direktmarketing (z. B. per E-Mail oder
          postalisch); Marketing; Absatzförderung.
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung".
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
          DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>Gewinnspiele und Wettbewerbe</h2>
      <p>
        Wir verarbeiten personenbezogene Daten der Teilnehmer von Gewinnspielen
        und Wettbewerben nur unter Einhaltung der einschlägigen
        Datenschutzbestimmungen, soweit die Verarbeitung zur Bereitstellung,
        Durchführung und Abwicklung des Gewinnspiels vertraglich erforderlich
        ist, die Teilnehmer in die Verarbeitung eingewilligt haben oder die
        Verarbeitung unseren berechtigten Interessen dient (z. B. an der
        Sicherheit des Gewinnspiels oder dem Schutz unserer Interessen vor
        Missbrauch durch mögliche Erfassung von IP-Adressen bei Einreichung von
        Gewinnspielbeiträgen). <br />
        Falls im Rahmen der Gewinnspiele Beiträge der Teilnehmer veröffentlicht
        werden (z. B. im Rahmen einer Abstimmung oder Präsentation der
        Gewinnspielbeiträge bzw. der Gewinner oder der Berichterstattung zum
        Gewinnspiel), weisen wir darauf hin, dass die Namen der Teilnehmer in
        diesem Zusammenhang ebenfalls veröffentlicht werden können. Die
        Teilnehmer können dem jederzeit widersprechen.
        <br />
        Findet das Gewinnspiel innerhalb einer Online-Plattform oder eines
        sozialen Netzwerks (z. B. Facebook oder Instagram, nachfolgend
        bezeichnet als "Online-Plattform") statt, gelten zusätzlich die
        Nutzungs- und Datenschutzbestimmungen der jeweiligen Plattformen. In
        diesen Fällen weisen wir darauf hin, dass wir für die im Rahmen des
        Gewinnspiels mitgeteilten Angaben der Teilnehmer verantwortlich sind und
        Anfragen im Hinblick auf das Gewinnspiel an uns zu richten sind. <br />
        Die Daten der Teilnehmer werden gelöscht, sobald das Gewinnspiel oder
        der Wettbewerb beendet sind und die Daten nicht mehr erforderlich sind,
        um die Gewinner zu informieren oder weil nicht mehr mit Rückfragen zum
        Gewinnspiel zu rechnen ist. Grundsätzlich werden die Daten der
        Teilnehmer spätestens 6 Monate nach Ende des Gewinnspiels gelöscht.
        Daten der Gewinner können länger einbehalten werden, um z. B. Rückfragen
        zu den Gewinnen beantworten oder die Gewinnleistungen erfüllen zu
        können; in diesem Fall richtet sich die Aufbewahrungsdauer nach der Art
        des Gewinns und beträgt z. B. bei Sachen oder Leistungen bis zu drei
        Jahre, um z. B. Gewährleistungsfälle bearbeiten zu können. Ferner können
        die Daten der Teilnehmer länger gespeichert werden, z. B. in Form der
        Berichterstattung zum Gewinnspiel in Online- und Offline-Medien. <br />
        Sofern Daten im Rahmen des Gewinnspiels auch zu anderen Zwecken erhoben
        wurden, richten sich deren Verarbeitung und die Aufbewahrungsdauer nach
        den Datenschutzhinweisen zu dieser Nutzung (z. B. im Fall einer
        Anmeldung zum Newsletter im Rahmen eines Gewinnspiels).
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Bestandsdaten (z. B. der vollständige
          Name, Wohnadresse, Kontaktinformationen, Kundennummer, etc.);
          Kontaktdaten (z. B. Post- und E-Mail-Adressen oder Telefonnummern).
          Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge
          sowie die sie betreffenden Informationen, wie z. B. Angaben zur
          Autorenschaft oder Zeitpunkt der Erstellung).
        </li>
        <li>
          <b>Betroffene Personen:</b> Gewinnspiel- und Wettbewerbsteilnehmer.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Durchführung von Gewinnspielen und
          Wettbewerben.
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung".
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche
          Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen
          (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>Webanalyse, Monitoring und Optimierung</h2>
      <p>
        Die Webanalyse (auch als „Reichweitenmessung" bezeichnet) dient der
        Auswertung der Besucherströme unseres Onlineangebots und kann Verhalten,
        Interessen oder demografische Informationen zu den Besuchern, wie
        beispielsweise Alter oder Geschlecht, als pseudonyme Werte umfassen.
        Mithilfe der Reichweitenanalyse können wir zum Beispiel erkennen, zu
        welcher Zeit unser Onlineangebot oder dessen Funktionen beziehungsweise
        Inhalte am häufigsten genutzt werden, oder zur Wiederverwendung
        einladen. Ebenso ist es uns möglich, nachzuvollziehen, welche Bereiche
        der Optimierung bedürfen. <br />
        Neben der Webanalyse können wir auch Testverfahren einsetzen, um etwa
        unterschiedliche Versionen unseres Onlineangebots oder seiner
        Bestandteile zu testen und zu optimieren.
        <br />
        Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken
        Profile, also zu einem Nutzungsvorgang zusammengefasste Daten, angelegt
        und Informationen in einem Browser bzw. in einem Endgerät gespeichert
        und dann ausgelesen werden. Zu den erhobenen Angaben gehören
        insbesondere besuchte Websites und dort genutzte Elemente sowie
        technische Auskünfte, wie etwa der verwendete Browser, das benutzte
        Computersystem sowie Angaben zu Nutzungszeiten. Sofern sich Nutzer in
        die Erhebung ihrer Standortdaten uns gegenüber oder gegenüber den
        Anbietern der von uns eingesetzten Dienste einverstanden erklärt haben,
        ist auch die Verarbeitung von Standortdaten möglich.
        <br />
        Darüber hinaus werden die IP-Adressen der Nutzer gespeichert. Jedoch
        nutzen wir ein IP-Masking-Verfahren (d. h. Pseudonymisierung durch
        Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im
        Rahmen von Webanalyse, A/B-Testings und Optimierung keine Klardaten der
        Nutzer (wie z. B. E-Mail-Adressen oder Namen) gespeichert, sondern
        Pseudonyme. Das heißt, wir als auch die Anbieter der eingesetzten
        Software kennen nicht die tatsächliche Identität der Nutzer, sondern nur
        die zum Zweck der jeweiligen Verfahren in deren Profilen gespeicherten
        Angaben. <br />
        Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
        Einwilligung in den Einsatz der Drittanbieter bitten, stellt die
        Rechtsgrundlage der Datenverarbeitung die Einwilligung dar. Ansonsten
        werden die Nutzerdaten auf Grundlage unserer berechtigten Interessen (d.
        h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen
        Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf
        die Informationen zur Verwendung von Cookies in dieser
        Datenschutzerklärung hinweisen.
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Nutzungsdaten (z. B. Seitenaufrufe und
          Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete
          Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und
          Funktionen); Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
          Personen).
        </li>
        <li>
          <b>Betroffene Personen:</b> Nutzer (z. B. Webseitenbesucher, Nutzer
          von Onlinediensten).
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Reichweitenmessung (z. B.
          Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile mit
          nutzerbezogenen Informationen (Erstellen von Nutzerprofilen);
          Bereitstellung unseres Onlineangebots und Nutzerfreundlichkeit.
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung"; Speicherung von Cookies von bis zu 2 Jahren (Sofern nicht
          anders angegeben, können Cookies und ähnliche Speichermethoden für
          einen Zeitraum von zwei Jahren auf den Geräten der Nutzer gespeichert
          werden).
        </li>
        <li>
          <b>Sicherheitsmaßnahmen:</b> IP-Masking (Pseudonymisierung der
          IP-Adresse).
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
          DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>Google Analytics:</b> Wir verwenden Google Analytics zur Messung
          und Analyse der Nutzung unseres Onlineangebotes auf der Grundlage
          einer pseudonymen Nutzeridentifikationsnummer. Diese
          Identifikationsnummer enthält keine eindeutigen Daten, wie Namen oder
          E-Mail-Adressen. Sie dient dazu, Analyseinformationen einem Endgerät
          zuzuordnen, um zu erkennen, welche Inhalte die Nutzer innerhalb eines
          oder verschiedener Nutzungsvorgänge aufgerufen haben, welche
          Suchbegriffe sie verwendet haben, diese erneut aufgerufen haben oder
          mit unserem Onlineangebot interagiert haben. Ebenso werden der
          Zeitpunkt der Nutzung und deren Dauer gespeichert, sowie die Quellen
          der Nutzer, die auf unser Onlineangebot verweisen und technische
          Aspekte ihrer Endgeräte und Browser. Dabei werden pseudonyme Profile
          von Nutzern mit Informationen aus der Nutzung verschiedener Geräte
          erstellt, wobei Cookies eingesetzt werden können. Google Analytics
          protokolliert und speichert keine individuellen IP-Adressen für
          EU-Nutzer. Analytics stellt jedoch grobe geografische Standortdaten
          bereit, indem es die folgenden Metadaten von IP-Adressen ableitet:
          Stadt (und der abgeleitete Breiten- und Längengrad der Stadt),
          Kontinent, Land, Region, Subkontinent (und ID-basierte Gegenstücke).
          Beim EU-Datenverkehr werden die IP-Adressdaten ausschließlich für
          diese Ableitung von Geolokalisierungsdaten verwendet, bevor sie sofort
          gelöscht werden. Sie werden nicht protokolliert, sind nicht zugänglich
          und werden nicht für weitere Verwendungszwecke genutzt. Wenn Google
          Analytics Messdaten sammelt, werden alle IP-Abfragen auf EU-basierten
          Servern durchgeführt, bevor der Verkehr zur Verarbeitung an
          Analytics-Server weitergeleitet wird;
          <ul>
            <li>
              <b>Dienstanbieter:</b> Google Ireland Limited, Gordon House,
              Barrow Street, Dublin 4, Irland;{" "}
            </li>
            <li>
              <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
              DSGVO);{" "}
            </li>

            <li>
              <p>
                <strong>Website: </strong>
                <a href="https://marketingplatform.google.com/intl/de/about/analytics/">
                  {" "}
                  Google Analytics{" "}
                </a>{" "}
                ;<strong> Sicherheitsmaßnahmen: </strong> IP-Masking
                (Pseudonymisierung der IP-Adresse ) ;
                <strong> Datenschutzerklärung: </strong>
                <a href="https://policies.google.com/privacy">
                  {" "}
                  Google Privacy Policy{" "}
                </a>
                ;<strong> Auftragsverarbeitungsvertrag: </strong>
                <a href="https://business.safety.google/adsprocessorterms/">
                  {" "}
                  Google Ads Processor Terms{" "}
                </a>
                ;<strong> Grundlage Drittlandtransfers: </strong> Data Privacy
                Framework (DPF) ;
                <strong> Widerspruchsmöglichkeit (Opt-Out): </strong>
                <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                  {" "}
                  Opt-Out-Plugin{" "}
                </a>
                ;
                <strong>
                  {" "}
                  Einstellungen für die Darstellung von Werbeeinblendungen:{" "}
                </strong>
                <a href="https://myadcenter.google.com/personalizationoff">
                  {" "}
                  Google Ad Settings{" "}
                </a>
                . Weitere Informationen:
                <a href="https://business.safety.google/adsservices/">
                  {" "}
                  Arten der Verarbeitung sowie der verarbeiteten Daten{" "}
                </a>
                .
              </p>
            </li>
          </ul>
        </li>
        <li>
          <b>Google Tag Manager:</b> Wir verwenden den Google Tag Manager, eine
          Software von Google, das es uns ermöglicht, sogenannte Website-Tags
          zentral über eine Benutzeroberfläche zu verwalten. Tags sind kleine
          Code-Elemente auf unserer Webseite, die dazu dienen,
          Besucheraktivitäten zu erfassen und zu analysieren. Diese Technologie
          unterstützt uns dabei, unsere Webseite und die darauf angebotenen
          Inhalte zu verbessern. Der Google Tag Manager selbst erstellt keine
          Nutzerprofile, speichert keine Cookies mit Nutzerprofilen und führt
          keine eigenständigen Analysen durch. Seine Funktion beschränkt sich
          darauf, die Einbindung und Verwaltung von Tools und Diensten, die wir
          auf unserer Webseite nutzen, zu vereinfachen und effizienter zu
          gestalten. Trotzdem wird bei der Nutzung des Google Tag Managers die
          IP-Adresse der Nutzer an Google übermittelt, was aus technischen
          Gründen erforderlich ist, um die von uns verwendeten Dienste zu
          implementieren. Auch können dabei Cookies gesetzt werden. Diese
          Datenverarbeitung erfolgt jedoch nur, wenn über den Tag Manager
          Dienste eingebunden werden. Für genauere Informationen zu diesen
          Diensten und ihrer Datenverarbeitung verweisen wir auf die
          weiterführenden Abschnitte dieser Datenschutzerklärung.
          <ul>
            <li>
              <b>Dienstanbieter:</b> Google Ireland Limited, Gordon House,
              Barrow Street, Dublin 4, Irland;{" "}
            </li>
            <li>
              <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
              DSGVO);{" "}
            </li>
            <li>
              <p>
                <strong>Website: </strong>
                <a href="https://marketingplatform.google.com">
                  Google Marketing Platform{" "}
                </a>{" "}
                ;<strong> Datenschutzerklärung: </strong>
                <a href="https://policies.google.com/privacy">
                  {" "}
                  Google Privacy Policy{" "}
                </a>{" "}
                ;<strong> Auftragsverarbeitungsvertrag: </strong>
                <a href="https://business.safety.google/adsprocessorterms">
                  {" "}
                  Google Ads Processor Terms{" "}
                </a>
                .<strong> Grundlage Drittlandtransfers: </strong> Data Privacy
                Framework (DPF).
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h2>Onlinemarketing</h2>
      <p>
        Wir verarbeiten personenbezogene Daten zum Zweck des Onlinemarketings,
        worunter insbesondere die Vermarktung von Werbeflächen oder die
        Darstellung von werbenden und sonstigen Inhalten (zusammenfassend als
        „Inhalte" bezeichnet) anhand potenzieller Interessen der Nutzer sowie
        die Messung ihrer Effektivität fallen können.
        <br />
        Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer
        Datei (der sogenannte „Cookie") gespeichert oder ähnliche Verfahren
        genutzt, mittels derer die für die Darstellung der vorgenannten Inhalte
        relevanten Angaben zum Nutzer gespeichert werden. Hierzu können
        beispielsweise betrachtete Inhalte, besuchte Websites, genutzte
        Onlinenetzwerke, aber auch Kommunikationspartner und technische Angaben
        gehören, wie etwa der verwendete Browser, das benutzte Computersystem
        sowie Auskünfte zu Nutzungszeiten und genutzten Funktionen. Sofern
        Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben, können
        auch diese verarbeitet werden.
        <br />
        Zudem werden die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir
        zur Verfügung stehende IP-Masking-Verfahren (d. h. Pseudonymisierung
        durch Kürzung der IP-Adresse) zum Nutzerschutz. Generell werden im
        Rahmen des Onlinemarketingverfahrens keine Klardaten der Nutzer (wie z.
        B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. Das
        heißt, wir als auch die Anbieter der Onlinemarketingverfahren kennen
        nicht die tatsächliche Nutzeridentität, sondern nur die in deren
        Profilen gespeicherten Angaben.
        <br />
        Die Aussagen in den Profilen werden im Regelfall in den Cookies oder
        mittels ähnlicher Verfahren gespeichert. Diese Cookies können später
        generell auch auf anderen Websites, die dasselbe
        Onlinemarketingverfahren einsetzen, ausgelesen und zum Zweck der
        Darstellung von Inhalten analysiert sowie mit weiteren Daten ergänzt und
        auf dem Server des Onlinemarketingverfahrensanbieters gespeichert
        werden.
        <br />
        Ausnahmsweise ist es möglich, Klardaten den Profilen zuzuordnen,
        vornehmlich dann, wenn die Nutzer zum Beispiel Mitglieder eines sozialen
        Netzwerks sind, dessen Onlinemarketingverfahren wir einsetzen und das
        Netzwerk die Nutzerprofile mit den vorgenannten Angaben verbindet. Wir
        bitten darum, zu beachten, dass Nutzer mit den Anbietern zusätzliche
        Abreden treffen können, etwa durch Einwilligung im Rahmen der
        Registrierung.
        <br />
        Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen
        über den Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen
        sogenannter Konversionsmessungen prüfen, welche unserer
        Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben,
        d. h. beispielsweise zu einem Vertragsschluss mit uns. Die
        Konversionsmessung wird alleine zur Erfolgsanalyse unserer
        Marketingmaßnahmen verwendet.
        <br />
        Solange nicht anders angegeben, bitten wir Sie, davon auszugehen, dass
        eingesetzte Cookies für einen Zeitraum von zwei Jahren gespeichert
        werden.
      </p>
      <p>
        <b>Hinweise zu Rechtsgrundlagen:</b> Sofern wir die Nutzer um deren
        Einwilligung in den Einsatz der Drittanbieter bitten, stellt die
        Rechtsgrundlage der Datenverarbeitung die Erlaubnis dar. Ansonsten
        werden die Daten der Nutzer auf Grundlage unserer berechtigten
        Interessen (d. h. Interesse an effizienten, wirtschaftlichen und
        empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
        möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in
        dieser Datenschutzerklärung hinweisen.
      </p>
      <h2>Hinweise zum Widerruf und Widerspruch: </h2>
      <p>
        Wir verweisen auf die Datenschutzhinweise der jeweiligen Anbieter und
        die zu den Anbietern angegebenen Widerspruchsmöglichkeiten (sog.
        "Opt-Out"). Sofern keine explizite Opt-Out-Möglichkeit angegeben wurde,
        besteht zum einen die Möglichkeit, dass Sie Cookies in den Einstellungen
        Ihres Browsers abschalten. Hierdurch können jedoch Funktionen unseres
        Onlineangebotes eingeschränkt werden. Wir empfehlen daher zusätzlich die
        folgenden Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige
        Gebiete gerichtet angeboten werden:{" "}
      </p>
      <ul>
        <li>
          Europa:{" "}
          <a href="https://www.youronlinechoices.eu">
            https://www.youronlinechoices.eu
          </a>
        </li>
        <li>
          Kanada:
          <a href="https://www.youradchoices.ca/choices">
            {" "}
            https://www.youradchoices.ca/choices
          </a>
        </li>
        <li>
          USA:
          <a href="https://www.aboutads.info/choices">
            {" "}
            https://www.aboutads.info/choices
          </a>
        </li>
        <li>
          Gebietsübergreifend:
          <a href="https://optout.aboutads.info">
            {" "}
            https://optout.aboutads.info
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Nutzungsdaten (z. B. Seitenaufrufe und
          Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete
          Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und
          Funktionen). Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
          Personen).
        </li>
        <li>
          <b>Betroffene Personen:</b> Nutzer (z. B. Webseitenbesucher, Nutzer
          von Onlinediensten).
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Reichweitenmessung (z. B.
          Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Tracking (z.
          B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies);
          Zielgruppenbildung; Marketing. Profile mit nutzerbezogenen
          Informationen (Erstellen von Nutzerprofilen).
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung". Speicherung von Cookies von bis zu 2 Jahren (Sofern nicht
          anders angegeben, können Cookies und ähnliche Speichermethoden für
          einen Zeitraum von zwei Jahren auf den Geräten der Nutzer gespeichert
          werden.).
        </li>
        <li>
          <b>Sicherheitsmaßnahmen:</b> IP-Masking (Pseudonymisierung der
          IP-Adresse).
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
          DSGVO).
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          {" "}
          <b>Instagram:</b> Soziales Netzwerk, ermöglicht das Teilen von Fotos
          und Videos, das Kommentieren und Favorisieren von Beiträgen,
          Nachrichtenversand, Abonnieren von Profilen und Seiten;
          <b> Dienstanbieter: </b> Meta Platforms Ireland Limited, Merrion Road,
          Dublin 4, D04 X2K5, Irland;
          <b> Rechtsgrundlagen: </b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO);
          <strong> Website: </strong>
          <a href="https://www.instagram.com">https://www.instagram.com </a> ;
          <strong> Datenschutzerklärung: </strong>
          <a href="https://privacycenter.instagram.com/policy/">
            {" "}
            https://privacycenter.instagram.com/policy/{" "}
          </a>{" "}
          .<strong> Grundlage Drittlandtransfers: </strong> Data Privacy
          Framework (DPF).
        </li>
      </ul>
      <ul>
        <li>
          {" "}
          <b>Facebook-Seiten: </b> Profile innerhalb des sozialen Netzwerks
          Facebook - Wir sind gemeinsam mit Meta Platforms Ireland Limited für
          die Erhebung (jedoch nicht die weitere Verarbeitung) von Daten der
          Besucher unserer Facebook-Seite (sog. "Fanpage") verantwortlich. Zu
          diesen Daten gehören Informationen zu den Arten von Inhalten, die
          Nutzer sich ansehen oder mit denen sie interagieren, oder die von
          ihnen vorgenommenen Handlungen (siehe unter „Von dir und anderen
          getätigte und bereitgestellte Dinge
          <b> in der Facebook-Datenrichtlinie: </b>{" "}
          <a href="https://www.facebook.com/privacy/policy/">
            https://www.facebook.com/privacy/policy/){" "}
          </a>{" "}
          ,sowie Informationen über die von den Nutzern genutzten Geräte (z. B.
          IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen,
          Cookie-Daten; siehe unter „Geräteinformationen
          <b> " in der Facebook-Datenrichtlinie: </b>{" "}
          <a href="https://www.facebook.com/privacy/policy/">
            https://www.facebook.com/privacy/policy/).{" "}
          </a>
          https://www.facebook.com/privacy/policy/. Wie in der
          Facebook-Datenrichtlinie unter „Wie verwenden wir diese
          Informationen?" erläutert, erhebt und verwendet Facebook Informationen
          auch, um Analysedienste, so genannte "Seiten-Insights", für
          Seitenbetreiber bereitzustellen, damit diese Erkenntnisse darüber
          erhalten, wie Personen mit ihren Seiten und mit den mit ihnen
          verbundenen Inhalten interagieren. Wir haben mit Facebook eine
          spezielle Vereinbarung abgeschlossen ("Informationen zu
          Seiten-Insights",{" "}
          <a href="https://www.facebook.com/legal/terms/page_controller_addendum">
            https://www.facebook.com/legal/terms/page_controller_addendum),
          </a>{" "}
          in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
          Facebook beachten muss und in der Facebook sich bereit erklärt hat die
          Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B. Auskünfte
          oder Löschungsanfragen direkt an Facebook richten). Die Rechte der
          Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch und
          Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die
          Vereinbarungen mit Facebook nicht eingeschränkt. Weitere Hinweise
          finden sich in den <b> "Informationen zu Seiten-Insights" </b>{" "}
          <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data">
            (https://www.facebook.com/legal/terms/information_about_page_insights_data).{" "}
          </a>{" "}
          Die gemeinsame Verantwortlichkeit beschränkt sich auf die Erhebung
          durch und Übermittlung von Daten an Meta Platforms Ireland Limited,
          ein Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der Daten
          liegt in der alleinigen Verantwortung von Meta Platforms Ireland
          Limited, was insbesondere die Übermittlung der Daten an die
          Muttergesellschaft Meta Platforms, Inc. in den USA betrifft;{" "}
          <b>Dienstanbieter: </b> Meta Platforms Ireland Limited, Merrion Road,
          Dublin 4, D04 X2K5, Irland; <b>Rechtsgrundlagen: </b> Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO ;
          <b>
            <strong> Website: </strong>{" "}
          </b>{" "}
          <a href="https://www.facebook.com">https://www.facebook.com; </a>{" "}
          <b>
            <strong> ; Datenschutzerklärung: </strong>{" "}
          </b>{" "}
          <a href="https://www.facebook.com/privacy/policy/">
            https://www.facebook.com/privacy/policy/.{" "}
          </a>{" "}
          <b>
            <strong> Grundlage Drittlandtransfers: </strong>{" "}
          </b>{" "}
          Data Privacy Framework (DPF).
        </li>
      </ul>
      <ul>
        <li>
          {" "}
          <b>TikTok: </b>Soziales Netzwerk, ermöglicht das Teilen von Fotos und
          Videos, das Kommentieren und Favorisieren von Beiträgen,
          Nachrichtenversand, Abonnieren von Accounts;
          <b> Dienstanbieter: </b> TikTok Technology Limited, 10 Earlsfort
          Terrace, Dublin, D02 T380, Irland und TikTok Information Technologies
          UK Limited, Kaleidoscope, 4 Lindsey Street, London, United Kingdom,
          EC1A 9HP;
          <b> Rechtsgrundlagen: </b>
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO;
          <b>
            <strong> Website: </strong>{" "}
          </b>{" "}
          <a href="https://www.tiktok.com.">https://www.tiktok.com. </a>{" "}
          <b>
            <strong> ; Datenschutzerklärung: </strong>{" "}
          </b>{" "}
          <a href="https://www.tiktok.com/de/privacy-policy">
            https://www.tiktok.com/de/privacy-policy.{" "}
          </a>
        </li>
      </ul>
      <h2>Plug-ins und eingebettete Funktionen sowie Inhalte</h2>
      <p>
        Wir binden Funktions- und Inhaltselemente in unser Onlineangebot ein,
        die von den Servern ihrer jeweiligen Anbieter (nachfolgend als
        „Drittanbieter" bezeichnet) bezogen werden. Dabei kann es sich zum
        Beispiel um Grafiken, Videos oder Stadtpläne handeln (nachfolgend
        einheitlich als „Inhalte" bezeichnet). <br />
        Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte
        die IP-Adresse der Nutzer verarbeiten, da sie ohne IP-Adresse die
        Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit
        für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir
        bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter
        die IP-Adresse lediglich zur Auslieferung der Inhalte anzuwenden.
        Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken,
        auch als „Web Beacons" bezeichnet) für statistische oder Marketingzwecke
        einsetzen. Durch die „Pixel-Tags" können Informationen, wie etwa der
        Besucherverkehr auf den Seiten dieser Website, ausgewertet werden. Die
        pseudonymen Informationen können darüber hinaus in Cookies auf dem Gerät
        der Nutzer gespeichert werden und unter anderem technische Auskünfte zum
        Browser und zum Betriebssystem, zu verweisenden Websites, zur
        Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebots
        enthalten, aber auch mit solchen Informationen aus anderen Quellen
        verbunden werden.
      </p>
      <h2>Hinweise zu Rechtsgrundlagen: </h2>
      <p>
        Sofern wir die Nutzer um ihre Einwilligung in den Einsatz der
        Drittanbieter bitten, stellt die Rechtsgrundlage der Datenverarbeitung
        die Erlaubnis dar. Ansonsten werden die Nutzerdaten auf Grundlage
        unserer berechtigten Interessen (d. h. Interesse an effizienten,
        wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In
        diesem Zusammenhang möchten wir Sie auch auf die Informationen zur
        Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
      </p>
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> Nutzungsdaten (z. B. Seitenaufrufe und
          Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete
          Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und
          Funktionen); Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
          Personen); Standortdaten (Angaben zur geografischen Position eines
          Gerätes oder einer Person). Event-Daten (Facebook) ("Event-Daten" sind
          Informationen, die beispielsweise über Meta-Pixel (sei es über Apps
          oder andere Kanäle) an den Anbieter Meta gesendet werden und sich auf
          Personen oder deren Aktionen beziehen. Zu diesen Daten zählen etwa
          Details zu Website-Besuchen, Interaktionen mit Inhalten und
          Funktionen, App-Installationen sowie Produktkäufe. Die Verarbeitung
          der Event-Daten erfolgt mit dem Ziel, Zielgruppen für Inhalte und
          Werbebotschaften (Custom Audiences) zu erstellen. Es ist wichtig zu
          beachten, dass Event-Daten keine tatsächlichen Inhalte wie verfasste
          Kommentare, keine Login-Informationen und keine Kontaktinformationen
          wie Namen, E-Mail-Adressen oder Telefonnummern umfassen. "Event-Daten"
          werden von Meta nach maximal zwei Jahren gelöscht, und die daraus
          gebildeten Zielgruppen verschwinden mit der Löschung unserer
          Meta-Nutzer-Konten.).
        </li>
        <li>
          <b>Betroffene Personen:</b> Nutzer (z. B. Webseitenbesucher, Nutzer
          von Onlinediensten).
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b> Bereitstellung unseres Onlineangebotes
          und Nutzerfreundlichkeit; Marketing. Profile mit nutzerbezogenen
          Informationen (Erstellen von Nutzerprofilen).
        </li>
        <li>
          <b>Aufbewahrung und Löschung:</b> Löschung entsprechend Angaben im
          Abschnitt "Allgemeine Informationen zur Datenspeicherung und
          Löschung". Speicherung von Cookies von bis zu 2 Jahren (Sofern nicht
          anders angegeben, können Cookies und ähnliche Speichermethoden für
          einen Zeitraum von zwei Jahren auf den Geräten der Nutzer gespeichert
          werden.).
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
          DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </h2>
      <ul>
        <li>
          <b>
            Einbindung von Drittsoftware, Skripten oder Frameworks (z. B.
            jQuery):
          </b>{" "}
          Wir binden in unser Onlineangebot Software ein, die wir von Servern
          anderer Anbieter abrufen (z. B. Funktions-Bibliotheken, die wir zwecks
          Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes
          verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse der
          Nutzer und können diese zu Zwecken der Übermittlung der Software an
          den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur
          Auswertung und Optimierung ihres Angebotes verarbeiten;
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO).
        </li>
        <li>
          <b>Facebook-Plugins und -Inhalte:</b> Facebook Social Plugins und
          Inhalte - Hierzu können z. B. Inhalte wie Bilder, Videos oder Texte
          und Schaltflächen gehören, mit denen Nutzer Inhalte dieses
          Onlineangebotes innerhalb von Facebook teilen können. Die Liste und
          das Aussehen der Facebook Social Plugins können hier eingesehen werden{" "}
          <a href="https://developers.facebook.com/docs/plugins/">
            {" "}
            https://developers.facebook.com/docs/plugins/
          </a>
          . Wir sind gemeinsam mit Meta Platforms Ireland Limited für die
          Erhebung oder den Erhalt im Rahmen einer Übermittlung (jedoch nicht
          die weitere Verarbeitung) von "Event-Daten", die Facebook mittels der
          Facebook-Social-Plugins (und Einbettungsfunktionen für Inhalte), die
          auf unserem Onlineangebot ausgeführt werden, erhebt oder im Rahmen
          einer Übermittlung zu folgenden Zwecken erhält, gemeinsam
          verantwortlich: a) Anzeige von Inhalten sowie Werbeinformationen, die
          den mutmaßlichen Interessen der Nutzer entsprechen; b) Zustellung
          kommerzieller und transaktionsbezogener Nachrichten (z. B. Ansprache
          von Nutzern via Facebook-Messenger); c) Verbesserung der
          Anzeigenauslieferung und Personalisierung von Funktionen und Inhalten
          (z. B. Verbesserung der Erkennung, welche Inhalte oder
          Werbeinformationen mutmaßlich den Interessen der Nutzer entsprechen).
          Wir haben mit Facebook eine spezielle Vereinbarung abgeschlossen
          ("Zusatz für Verantwortliche",{" "}
          <a href="https://www.facebook.com/legal/controller_addendum">
            https://www.facebook.com/legal/controller_addendum
          </a>
          ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
          Facebook beachten muss (
          <a href="https://www.facebook.com/legal/terms/data_security_terms">
            https://www.facebook.com/legal/terms/data_security_terms
          </a>
          ) und in der Facebook sich bereit erklärt hat die Betroffenenrechte zu
          erfüllen (d. h. Nutzer können z. B. Auskünfte oder Löschungsanfragen
          direkt an Facebook richten). Hinweis: Wenn Facebook uns Messwerte,
          Analysen und Berichte bereitstellt (die aggregiert sind, d. h. keine
          Angaben zu einzelnen Nutzern enthalten und für uns anonym sind), dann
          erfolgt diese Verarbeitung nicht im Rahmen der gemeinsamen
          Verantwortlichkeit, sondern auf Grundlage eines
          Auftragsverarbeitungsvertrages ("Datenverarbeitungsbedingungen",{" "}
          <a href="https://www.facebook.com/legal/terms/dataprocessing">
            https://www.facebook.com/legal/terms/dataprocessing
          </a>
          ), der "Datensicherheitsbedingungen" (
          <a href="https://www.facebook.com/legal/terms/data_security_terms">
            https://www.facebook.com/legal/terms/data_security_terms
          </a>
          ) sowie im Hinblick auf die Verarbeitung in den USA auf Grundlage von
          Standardvertragsklauseln ("Facebook-EU-Datenübermittlungszusatz",{" "}
          <a href="https://www.facebook.com/legal/EU_data_transfer_addendum">
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </a>
          ). Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung,
          Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde), werden
          durch die Vereinbarungen mit Facebook nicht eingeschränkt;
          Dienstanbieter: Meta Platforms Ireland Limited, Merrion Road, Dublin
          4, D04 X2K5, Irland; Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S.
          1 lit. a) DSGVO); Website:{" "}
          <a href="https://www.facebook.com">https://www.facebook.com</a>;
          Datenschutzerklärung:{" "}
          <a href="https://www.facebook.com/privacy/policy/">
            https://www.facebook.com/privacy/policy/
          </a>
          . Grundlage Drittlandtransfers: Data Privacy Framework (DPF).
        </li>
        <li>
          <b>Google Fonts (Bereitstellung auf eigenem Server):</b>{" "}
          Bereitstellung von Schriftarten-Dateien zwecks einer
          nutzerfreundlichen Darstellung unseres Onlineangebotes;
          Dienstanbieter: Die Google Fonts werden auf unserem Server gehostet,
          es werden keine Daten an Google übermittelt; Rechtsgrundlagen:
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
        <li>
          <b>Google Maps:</b> Wir binden die Landkarten des Dienstes "Google
          Maps" des Anbieters Google ein. Zu den verarbeiteten Daten können
          insbesondere IP-Adressen und Standortdaten der Nutzer gehören;
          Dienstanbieter: Google Cloud EMEA Limited, 70 Sir John Rogerson’s
          Quay, Dublin 2, Irland; Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1
          S. 1 lit. a) DSGVO); Website:{" "}
          <a href="https://mapsplatform.google.com/">
            https://mapsplatform.google.com/
          </a>
          ; Datenschutzerklärung:{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          . Grundlage Drittlandtransfers: Data Privacy Framework (DPF).
        </li>
        <li>
          <b>Instagram-Plugins und -Inhalte:</b> Instagram Plugins und -Inhalte
          - Hierzu können z. B. Inhalte wie Bilder, Videos oder Texte und
          Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes
          innerhalb von Instagram teilen können. Wir sind gemeinsam mit Meta
          Platforms Ireland Limited für die Erhebung oder den Erhalt im Rahmen
          einer Übermittlung (jedoch nicht die weitere Verarbeitung) von
          "Event-Daten", die Facebook mittels Funktionen von Instagram (z. B.
          Einbettungsfunktionen für Inhalte), die auf unserem Onlineangebot
          ausgeführt werden, erhebt oder im Rahmen einer Übermittlung zu
          folgenden Zwecken erhält, gemeinsam verantwortlich: a) Anzeige von
          Inhalten sowie Werbeinformationen, die den mutmaßlichen Interessen der
          Nutzer entsprechen; b) Zustellung kommerzieller und
          transaktionsbezogener Nachrichten (z. B. Ansprache von Nutzern via
          Facebook-Messenger); c) Verbesserung der Anzeigenauslieferung und
          Personalisierung von Funktionen und Inhalten (z. B. Verbesserung der
          Erkennung, welche Inhalte oder Werbeinformationen mutmaßlich den
          Interessen der Nutzer entsprechen). Wir haben mit Facebook eine
          spezielle Vereinbarung abgeschlossen ("Zusatz für Verantwortliche",{" "}
          <a href="https://www.facebook.com/legal/controller_addendum">
            https://www.facebook.com/legal/controller_addendum
          </a>
          ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
          Facebook beachten muss (
          <a href="https://www.facebook.com/legal/terms/data_security_terms">
            https://www.facebook.com/legal/terms/data_security_terms
          </a>
          ) und in der Facebook sich bereit erklärt hat die Betroffenenrechte zu
          erfüllen (d. h. Nutzer können z. B. Auskünfte oder Löschungsanfragen
          direkt an Facebook richten). Hinweis: Wenn Facebook uns Messwerte,
          Analysen und Berichte bereitstellt (die aggregiert sind, d. h. keine
          Angaben zu einzelnen Nutzern enthalten und für uns anonym sind), dann
          erfolgt diese Verarbeitung nicht im Rahmen der gemeinsamen
          Verantwortlichkeit, sondern auf Grundlage eines
          Auftragsverarbeitungsvertrages ("Datenverarbeitungsbedingungen",{" "}
          <a href="https://www.facebook.com/legal/terms/dataprocessing">
            https://www.facebook.com/legal/terms/dataprocessing
          </a>
          ), der "Datensicherheitsbedingungen" (
          <a href="https://www.facebook.com/legal/terms/data_security_terms">
            https://www.facebook.com/legal/terms/data_security_terms
          </a>
          ) sowie im Hinblick auf die Verarbeitung in den USA auf Grundlage von
          Standardvertragsklauseln ("Facebook-EU-Datenübermittlungszusatz",{" "}
          <a href="https://www.facebook.com/legal/EU_data_transfer_addendum">
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </a>
          ). Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung,
          Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde), werden
          durch die Vereinbarungen mit Facebook nicht eingeschränkt;
          Dienstanbieter: Meta Platforms Ireland Limited, Merrion Road, Dublin
          4, D04 X2K5, Irland; Rechtsgrundlagen: Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO); Website:{" "}
          <a href="https://www.instagram.com">https://www.instagram.com</a>;
          Datenschutzerklärung:{" "}
          <a href="https://privacycenter.instagram.com/policy/">
            https://privacycenter.instagram.com/policy/
          </a>
          .
        </li>
      </ul>
       <h2>Änderung und Aktualisierung</h2>
       <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z. B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>

<p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.</p>

<p>Für uns zuständige Aufsichtsbehörde:</p>

<p>Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen</p>

<p>Postfach 20 04 44<br />40102 Düsseldorf</p>

<p>Kavalleriestraße 2-4<br />40213 Düsseldorf</p>
<p>Telefon: 02 11/384 24-0<br/>E-Mail: <a href="mailto:poststelle@ldi.nrw.de">poststelle@ldi.nrw.de</a><br/>Internet: <a href="https://www.ldi.nrw.de" target="_blank">https://www.ldi.nrw.de</a></p>

   <h2>Begriffsdefinitionen</h2>
  <p> In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis dienen.</p>
  <ul>
  <li>
    <strong>Bestandsdaten:</strong> Bestandsdaten umfassen wesentliche Informationen, die für die Identifikation und Verwaltung von Vertragspartnern, Benutzerkonten, Profilen und ähnlichen Zuordnungen notwendig sind. Diese Daten können u.a. persönliche und demografische Angaben wie Namen, Kontaktinformationen (Adressen, Telefonnummern, E-Mail-Adressen), Geburtsdaten und spezifische Identifikatoren (Benutzer-IDs) beinhalten. Bestandsdaten bilden die Grundlage für jegliche formelle Interaktion zwischen Personen und Diensten, Einrichtungen oder Systemen, indem sie eine eindeutige Zuordnung und Kommunikation ermöglichen.
  </li>
  <li>
    <strong>Inhaltsdaten:</strong> Inhaltsdaten umfassen Informationen, die im Zuge der Erstellung, Bearbeitung und Veröffentlichung von Inhalten aller Art generiert werden. Diese Kategorie von Daten kann Texte, Bilder, Videos, Audiodateien und andere multimediale Inhalte einschließen, die auf verschiedenen Plattformen und Medien veröffentlicht werden. Inhaltsdaten sind nicht nur auf den eigentlichen Inhalt beschränkt, sondern beinhalten auch Metadaten, die Informationen über den Inhalt selbst liefern, wie Tags, Beschreibungen, Autoreninformationen und Veröffentlichungsdaten.
  </li>
  <li>
    <strong>Kontaktdaten:</strong> Kontaktdaten sind essentielle Informationen, die die Kommunikation mit Personen oder Organisationen ermöglichen. Sie umfassen u.a. Telefonnummern, postalische Adressen und E-Mail-Adressen, sowie Kommunikationsmittel wie soziale Medien-Handles und Instant-Messaging-Identifikatoren.
  </li>
  <li>
    <strong>Meta-, Kommunikations- und Verfahrensdaten:</strong> Meta-, Kommunikations- und Verfahrensdaten sind Kategorien, die Informationen über die Art und Weise enthalten, wie Daten verarbeitet, übermittelt und verwaltet werden. Meta-Daten, auch bekannt als Daten über Daten, umfassen Informationen, die den Kontext, die Herkunft und die Struktur anderer Daten beschreiben. Sie können Angaben zur Dateigröße, dem Erstellungsdatum, dem Autor eines Dokuments und den Änderungshistorien beinhalten. Kommunikationsdaten erfassen den Austausch von Informationen zwischen Nutzern über verschiedene Kanäle, wie E-Mail-Verkehr, Anrufprotokolle, Nachrichten in sozialen Netzwerken und Chat-Verläufe, inklusive der beteiligten Personen, Zeitstempel und Übertragungswege. Verfahrensdaten beschreiben die Prozesse und Abläufe innerhalb von Systemen oder Organisationen, einschließlich Workflow-Dokumentationen, Protokolle von Transaktionen und Aktivitäten, sowie Audit-Logs, die zur Nachverfolgung und Überprüfung von Vorgängen verwendet werden.
  </li>
  <li>
    <strong>Nutzungsdaten:</strong> Nutzungsdaten beziehen sich auf Informationen, die erfassen, wie Nutzer mit digitalen Produkten, Dienstleistungen oder Plattformen interagieren. Diese Daten umfassen eine breite Palette von Informationen, die aufzeigen, wie Nutzer Anwendungen nutzen, welche Funktionen sie bevorzugen, wie lange sie auf bestimmten Seiten verweilen und über welche Pfade sie durch eine Anwendung navigieren. Nutzungsdaten können auch die Häufigkeit der Nutzung, Zeitstempel von Aktivitäten, IP-Adressen, Geräteinformationen und Standortdaten einschließen. Sie sind besonders wertvoll für die Analyse des Nutzerverhaltens, die Optimierung von Benutzererfahrungen, das Personalisieren von Inhalten und das Verbessern von Produkten oder Dienstleistungen. Darüber hinaus spielen Nutzungsdaten eine entscheidende Rolle beim Erkennen von Trends, Vorlieben und möglichen Problembereichen innerhalb digitaler Angebote.
  </li>
  <li>
    <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene Person") beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z. B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
  </li>
  <li>
    <strong>Profile mit nutzerbezogenen Informationen:</strong> Die Verarbeitung von "Profilen mit nutzerbezogenen Informationen", bzw. kurz "Profilen" umfasst jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen (je nach Art der Profilbildung können dazu unterschiedliche Informationen betreffend die Demographie, Verhalten und Interessen, wie z. B. die Interaktion mit Webseiten und deren Inhalten, etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen (z. B. die Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies und Web-Beacons eingesetzt.
  </li>
  <li>
    <strong>Protokolldaten:</strong> Protokolldaten sind Informationen über Ereignisse oder Aktivitäten, die in einem System oder Netzwerk protokolliert wurden. Diese Daten enthalten typischerweise Informationen wie Zeitstempel, IP-Adressen, Benutzeraktionen, Fehlermeldungen und andere Details über die Nutzung oder den Betrieb eines Systems. Protokolldaten werden oft zur Analyse von Systemproblemen, zur Sicherheitsüberwachung oder zur Erstellung von Leistungsberichten verwendet.
  </li>
  <li>
    <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web Analytics bezeichnet) dient der Auswertung der Besucherströme eines Onlineangebotes und kann das Verhalten oder Interessen der Besucher an bestimmten Informationen, wie z. B. Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können Betreiber von Onlineangeboten z. B. erkennen, zu welcher Zeit Nutzer ihre Webseiten besuchen und für welche Inhalte sie sich interessieren. Dadurch können sie z. B. die Inhalte der Webseiten besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.
  </li>
  <li>
    <strong>Standortdaten:</strong> Standortdaten entstehen, wenn sich ein mobiles Gerät (oder ein anderes Gerät mit den technischen Voraussetzungen einer Standortbestimmung) mit einer Funkzelle, einem WLAN oder ähnlichen technischen Mitteln und Funktionen der Standortbestimmung, verbindet. Standortdaten dienen der Angabe, an welcher geografisch bestimmbaren Position der Erde sich das jeweilige Gerät befindet. Standortdaten können z. B. eingesetzt werden, um Kartenfunktionen oder andere von einem Ort abhängige Informationen darzustellen.
  </li>
  <li>
    <strong>Tracking:</strong> Vom "Tracking" spricht man, wenn das Verhalten von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann. Im Regelfall werden im Hinblick auf die genutzten Onlineangebote Verhaltens- und Interessensinformationen in Cookies oder auf Servern der Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling). Diese Informationen können anschließend z. B. eingesetzt werden, um den Nutzern Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen entsprechen.
  </li>
  <li>
    <strong>Verantwortlicher:</strong> Als "Verantwortlicher" wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
  </li>
  <li>
    <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
  </li>
  <li>
    <strong>Vertragsdaten:</strong> Vertragsdaten sind spezifische Informationen, die sich auf die Formalisierung einer Vereinbarung zwischen zwei oder mehr Parteien beziehen. Sie dokumentieren die Bedingungen, unter denen Dienstleistungen oder Produkte bereitgestellt, getauscht oder verkauft werden. Diese Datenkategorie ist wesentlich für die Verwaltung und Erfüllung vertraglicher Verpflichtungen und umfasst sowohl die Identifikation der Vertragsparteien als auch die spezifischen Bedingungen und Konditionen der Vereinbarung. Vertragsdaten können Start- und Enddaten des Vertrages, die Art der vereinbarten Leistungen oder Produkte, Preisvereinbarungen, Zahlungsbedingungen, Kündigungsrechte, Verlängerungsoptionen und spezielle Bedingungen oder Klauseln umfassen. Sie dienen als rechtliche Grundlage für die Beziehung zwischen den Parteien und sind entscheidend für die Klärung von Rechten und Pflichten, die Durchsetzung von Ansprüchen und die Lösung von Streitigkeiten.
  </li>
  <li>
    <strong>Zahlungsdaten:</strong> Zahlungsdaten umfassen sämtliche Informationen, die zur Abwicklung von Zahlungstransaktionen zwischen Käufern und Verkäufern benötigt werden. Diese Daten sind von entscheidender Bedeutung für den elektronischen Handel, das Online-Banking und jede andere Form der finanziellen Transaktion. Sie beinhalten Details wie Kreditkartennummern, Bankverbindungen, Zahlungsbeträge, Transaktionsdaten, Verifizierungsnummern und Rechnungsinformationen. Zahlungsdaten können auch Informationen über den Zahlungsstatus, Rückbuchungen, Autorisierungen und Gebühren enthalten.
  </li>
  <li>
    <strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung (englisch "Custom Audiences") spricht man, wenn Zielgruppen für Werbezwecke, z. B. Einblendung von Werbeanzeigen bestimmt werden. So kann z. B. anhand des Interesses eines Nutzers an bestimmten Produkten oder Themen im Internet geschlussfolgert werden, dass dieser Nutzer sich für Werbeanzeigen für ähnliche Produkte oder den Onlineshop, in dem er die Produkte betrachtet hat, interessiert. Von "Lookalike Audiences" (bzw. ähnlichen Zielgruppen) spricht man wiederum, wenn die als geeignet eingeschätzten Inhalte Nutzern angezeigt werden, deren Profile, bzw. Interessen mutmaßlich den Nutzern zu denen die Profile gebildet wurden, entsprechen. Zur Zwecken der Bildung von Custom Audiences und Lookalike Audiences werden im Regelfall Cookies und Web-Beacons eingesetzt.
  </li>
</ul>
        </div>
      )
      }
    </div>
  );
};

export default PrivacyPolicyEng;
