import React, { Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { FadeLoader } from "react-spinners";
import Success from "./Pages/Success";
import Failed from "./Pages/Failed";
import AdminStaff from "./Component/AddStaff/AdminStaff";
import Verification from "./Pages/Verification";
import ImprintEnglish from "./Component/imprint/ImprintEnglish";
import PrivacyPolicyEng from "./Component/PrivacyPolicy/PrivacyPolicyEng";
import TermsAndConditions from "./Component/termsCondition/TermsAndConditions";


const Home = React.lazy(() => import("./Pages/Home"));
const AboutUs = React.lazy(() => import("./Pages/AboutUs"));
const BeardContest = React.lazy(() => import("./Pages/BeardContest"));
const BuyNow = React.lazy(() => import("./Pages/BuyNow"));
const BarberShops = React.lazy(() => import("./Pages/BarberShops"));
const BarberDetails = React.lazy(() => import("./Pages/BarberDetails"));
const ShopOnline = React.lazy(() => import("./Pages/ShopOnline"));
const MemberLogin = React.lazy(() =>
  import("./Component/Views/Member/Login/MemberLogin")
);
const ProductDetails = React.lazy(() => import("./Pages/ProductDetails"));
const ContactUs = React.lazy(() => import("./Component/ContactUs/ContactUs"));
const CartPage = React.lazy(() => import("./Pages/CartPage"));
const MemberSignUp = React.lazy(() =>
  import("./Component/Views/Member/Signup/MemberSignUp")
);
const PrivateRoutes = React.lazy(() =>
  import("../src/Component/utils/ProtectedRoutes")
);
const ForgetPassword = React.lazy(() =>
  import("./Component/Views/Member/ForgetPassword/ForgetPassword")
);
const Otp = React.lazy(() => import("./Component/Staff/Otp"));
const AddStaff = React.lazy(() => import("./Component/AddStaff/AddStaff"));
const Navbar = React.lazy(() => import("./Component/Navbar/Navbar"));
const ScrollTop = React.lazy(() => import("./Component/utils/ScrollTop"));
const ResetPassword = React.lazy(() =>
  import("./Component/Staff/ResetPasswrod")
);

function App() {
  const location = useLocation();

  const displayNavbar = [
    "/addstaff/:id",
    "/success",
    "/failed",
    "/otppassword",
    "/verify/:id",
  ].includes(location.pathname);

  return (
    <>
      {!displayNavbar && (
        <Suspense
          fallback={
            <div
              className="flex justify-center items-center h-screen mx-auto loading-container"
              style={{
                backgroundColor: "#1c1c1c",
                height: "100vh",
              }}
            >
              <FadeLoader color="#ba5eef" />
            </div>
          }
        >
          <Navbar />
        </Suspense>
      )}

      <Suspense
        fallback={
          <div
            className="flex justify-center items-center h-screen loading-container"
            style={{ backgroundColor: "#1c1c1c", height: "100vh" }}
          >
            <div className="text-center">
              <FadeLoader color="#ba5eef" />
            </div>
          </div>
        }
      >
        <ScrollTop />
        <Routes>
          {/* Protected Routes */}
          <Route element={<PrivateRoutes />}>
            {/* <Route path="/buynow/:data" element={<BuyNow />} /> */}
            <Route path="/buynow" element={<BuyNow />} />
            <Route path="/cart/:id" element={<CartPage />} />
          </Route>

          <Route path="/addstaff/:id" element={<AddStaff />} />
          <Route path="/addAdminstaff/:id" element={<AdminStaff />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/beardcontest" element={<BeardContest />} />
          <Route path="/barbershop" element={<BarberShops />} />
          <Route path="/barberdetail/:id" element={<BarberDetails />} />
          <Route path="/onlineshop" element={<ShopOnline />} />
          <Route path="/productdetail/:id" element={<ProductDetails />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failed" element={<Failed />} />
          <Route path="/verify/:id" element={<Verification />} />
          {/* <Route path="/Datenschutzerklärung" element={<PrivacyPolicy />} /> */}
          <Route path="/PrivacyPolicy" element={<PrivacyPolicyEng />} />
          <Route path="/Imprint" element={<ImprintEnglish />} />
          {/* <Route path="/Impressum" element={<ImprintGerman />} /> */}
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />

          {/* Routes for Login */}
          <Route path="/memberlogin" element={<MemberLogin />} />
          <Route path="/membersignup" element={<MemberSignUp />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route
            path="/resetpassword/:resetToken"
            element={<ResetPassword />}
          />
          <Route path="/otppassword" element={<Otp />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
