import React, { useState } from "react";
import "./AddStaff.css";
import { FileUploader } from "react-drag-drop-files";
import { BASEURL } from "../API/ApiUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles
import { useNavigate, useParams } from "react-router-dom";

const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

const AdminStaff = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  // State variables for form inputs and validations
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [file, setFile] = useState(null);

  // Validation error messages
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  //////////////////////////////handling image ///////////////////////////////////
  // Handler function for file upload
  const handleChange = (file) => {
    setFile(file);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Form submission handler with validations
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password validation
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    // Clear any previous validation errors
    setConfirmPasswordError("");

    // Create a FormData object to send form data as a multipart/form-data
    const formData = new FormData();

    // Append form data to the FormData object
    formData.append("username", username);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("cpassword", confirmPassword);
    formData.append("staffimage", file);
    formData.append("staffimage", "Staff");
    formData.append("adminId", id);

    try {
      // Make a POST request to the server
      const response = await axios.post(
        `https://api.bartfreunde.de/api/auth/staff/setAdmin-password`,
        formData
      );

      // Check if the response status is 201 (Created)
      if (response.status === 201) {
        console.log(response.data);
        toast.success("Staff Added!", {
          position: "top-right",
          autoClose: 5000, // You can adjust the duration as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Clear form inputs on successful submission
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setFile("");
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000, // You can adjust the duration as needed
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Password change handler with validation
  const handlePasswordChange = (value) => {
    setPassword(value);
    setConfirmPasswordError(
      value !== confirmPassword ? "Passwords do not match" : ""
    );
  };

  // Confirm Password change handler with validation
  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setConfirmPasswordError(value !== password ? "Passwords do not match" : "");
  };

  return (
    <>
      {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000} // Adjust the duration as needed
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Main container for the staff form */}
      <div className="container-fluid" id="staff-main">
        <div className="staff-container">
          {/* Heading */}
          <div>
            <h1 className="heading">Add Staff</h1>
          </div>

          {/* Form */}
          <div>
            <form className="staff-form" onSubmit={handleSubmit}>
              {/* Username input */}
              <input
                type="text"
                id="username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                required
                className="staff-input"
                placeholder="UserName"
              />

              {/* Email input 
              <input
                type="email"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
                className="staff-input"
                placeholder="Email"
              />*/}

              {/* Password input with validation */}
              <input
                type="password"
                id="password"
                value={password}
                onChange={(event) => handlePasswordChange(event.target.value)}
                required
                className="staff-input"
                placeholder="Password"
              />
              {passwordError && <p className=" text-danger">{passwordError}</p>}

              {/* Confirm Password input with validation */}
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(event) =>
                  handleConfirmPasswordChange(event.target.value)
                }
                required
                className="staff-input"
                placeholder="Confirm Password"
              />
              {confirmPasswordError && (
                <p className="error-message text-danger">
                  {confirmPasswordError}
                </p>
              )}

              {/* Image uploader component */}
              <div className="image-uploader">
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  className="file-uploader"
                />
              </div>

              {/* Submit button */}
              <button className="btn-1" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminStaff;
