import React from "react";
import "./AboutComunity.css";
import communityimg from "../../Images/aboutcomunity.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AboutComunity = (props) => {
  //here we are passing the background color as props as we are changing its color in another component
  const { bgColor } = props;
  const style = { backgroundColor: bgColor };

  const { t } = useTranslation("Translate");

  return (
    <>
      <div className="container-fluid p-5" id="AboutComunity" style={style}>
        <div className="row">
          <div className="col-sm-5 col-md-5">
            <div className="communityimage">
              <img
                src={communityimg}
                alt="community image"
                className="community-img"
              />
            </div>
          </div>
          <div className="col-sm-7 col-md-7 ">
            <div className="community-content">
              <span
                className="heading "
                id="about-community-heading"
              >
                {t("AboutOurCommunity")}
              </span>
              <span className="secondary-text  ">
                {t("About-P")}
              </span>
              <div className="community-btn">
                <Link to="/about" className=" learnmore ">
                  {t("LearnMore")} &nbsp; &nbsp;<span>&rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutComunity;
