import React from 'react'
import paymentFailed from "../Images/remove.png"
import { Link } from 'react-router-dom'
const Failed = () => {
  return (
    <>
      <div className="app-container">
          <div className=" text-white p-6  md:mx-auto text-center">
            <img src={paymentFailed} alt='payment icon' className=' my-3' style={{width:"100px", height:"100px"}}/>
            <div className="text-center">
                <h3 className="md:text-2xl text-base  font-semibold text-center">Payment Failed!</h3>
                <p className=" my-2">Thank you for completing your secure online payment.</p>
                <p> Have a great day!  </p>
                <div className="py-10 text-center">
                    <Link to="/" className="btn btn-1">
                        GO BACK 
                   </Link>
                </div>
            </div>
        </div>
      </div>

    </>
  )
}

export default Failed