import React from 'react'
import { Link } from 'react-router-dom'
import paymentSuccess from "../Images/payment.svg"

const Success = () => {
  return (
    <>
    <div className="app-container">
          <div className=" text-white p-6  md:mx-auto text-center">
            <img src={paymentSuccess} alt='payment icon ' style={{width:"200px", height:"200px"}}/>
            <div className="text-center">
                <h3 className="md:text-2xl text-base  font-semibold text-center">Payment Done!</h3>
                <p className=" my-2">Thank you for completing your secure online payment.</p>
                <p> Have a great day!  </p>
                <div className="py-10 text-center">
                    <Link to="/" className="btn btn-1">
                        GO BACK 
                   </Link>
                </div>
            </div>
        </div>
      </div>
      </>
  )
}

export default Success