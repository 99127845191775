import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../Component/API/ApiUrl";

const initialState = {
  value: [],
  loading: false,
  error: null,
};

//actions to fetch data
export const getProducts = createAsyncThunk("getProducts", async () => {
  console.log("get product is called");
  const response = await axios.get(`https://api.bartfreunde.de/api/auth/products`);
  console.log(response.data, "data recieved ");
  const result = response.data;
  return result;
});

export const ProductSlice = createSlice({
  name: "product",
  initialState,
  
  //extra reducers are used to add extra functionality
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.loading = true;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
      state.value = action.payload.products;
    },
    [getProducts.rejected]: (state, action) => {
      state.loading = false;
      state.value = action.payload;
    },
  },
});

export default ProductSlice.reducer;
