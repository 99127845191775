import React from "react";
import "./Footer.css";
import logo from "../../Images/Logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const Footer = () => {
  const { t } = useTranslation("Translate");
  const navigate = useNavigate();
  const currentLanguage = i18next.language;

  const handleNavigation = (path) => {
    navigate(path, { state: { lang: currentLanguage } });
  };
  return (
    <>
      <div className="empty"></div>
      <div className="container-fluid" id="footer">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-lg-3">
            <div className="footer-content">
              <img src={logo} alt="logo" className="footer-logo" />
              <span className="footer-heading">
                {/* Beard Friends */}
                {t("BeardFriends")}
              </span>
              <span className="footer-des">
                {/* The barber online community offered by Beard Friends is a great
                way for individuals to connect and share their passion for
                proper beard maintenance. The community allows users to find
                nearby barber shops */}
                {t("Beard-P2")}
              </span>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-3">
            <div className="footer-content">
              <span className="footer-heading">Pages</span>
              <ul className="footer-ul">
                <li>
                  <Link to="/about" className="footer-link">
                    {t("About")}
                  </Link>
                </li>
                <li>
                  <Link to="/beardcontest" className="footer-link">
                    {t("BeardContest")}
                  </Link>
                </li>
                <li>
                  <Link to="/barbershop" className="footer-link">
                    {t("BeardShop")}
                  </Link>
                </li>
                <li>
                  <Link to="/onlineshop" className="footer-link">
                    {t("OnlineShop")}
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="footer-link">
                    {t("Contact")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-3">
            <div className="footer-content">
              <span className="footer-heading">Legal</span>
              <ul className="footer-ul ">
                <li className="legal_li"><span onClick={()=>handleNavigation("/TermsAndConditions")} >{t("TermsandConditions")}</span></li>
                <li className="legal_li" >
                <span className="term-condition legal_li"
                    onClick={() => handleNavigation("/PrivacyPolicy")}
                >
                {t("PrivacyPolicy")}
                </span></li>
                <li className="legal_li">
                <span
                      className=" term-condition legal_li"
                      onClick={() => handleNavigation("/Imprint")}
                    >
                      {t("Imprint")}
                    </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-3">
            <div className="footer-form">
              <span className="footer-heading2">
                {t("SubscribetoourNewsLetter")}
              </span>
              <form>
                <input
                  type="text"
                  className="footer-input"
                  placeholder={t("Fullname")}
                />
                <input
                  type="text"
                  className="footer-input"
                  placeholder={t("Email_footer")}
                />
                <span className="form-text text-white">
                  {t("Subscribe")}
                  <span >
                    <span 
                    className="term-condition c legal_li "
                    onClick={() => handleNavigation("/PrivacyPolicy")}
                    >
                      {t("PrivacyPolicy")}
                    </span>
                    
                    <br />
                  </span>
                </span>

                <button className="btn-1 mx-2 my-3">{t("Submit")}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
