// cartSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../Component/API/ApiUrl";
import { toast } from "react-toastify";

// Define an initial state
const initialState = {
  cartItems: [],
  status: "idle",
  error: null,
};

// Create an async thunk for adding a product to the cart
export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (productData, { rejectWithValue }) => {
    try {
      // Make an API call to add the product to the cart
      console.log("i am called", productData);
      const response = await axios.post(
        `https://api.bartfreunde.de/api/auth/addToCart`,
        productData
      );
      if (response.status == 200) {
        console.log("success abb cart maa product haa ", response.data[1]);
        toast.success("Product added to cart");
      }
      return response.data; // Assuming the API returns the updated cart data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Create an async thunk for fetching a product to the cart
export const fetchCartData = createAsyncThunk(
  "cart/fetchCartData",
  async (userId, { rejectWithValue }) => {
    try {
      // Make an API call to fetch the cart data for the given userId
      const response = await axios.get(
        `https://api.bartfreunde.de/api/auth/getProductsInCart/${userId}`
      );
      console.log(response.data, "cart data fetched");
      return response.data; // Assuming the API returns the cart data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create an async thunk for removing a product from the cart
export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async ({ cartId, productIds, id }, { rejectWithValue, dispatch }) => {
    console.log(id, "///", cartId, "//", productIds);
    try {
      // Make an API call to remove the selected product(s) from the cart
      const response = await axios.put(
        `https://api.bartfreunde.de/api/auth/removeProductFromCart`, {
          cartId,
          productIds
        }
      );

      // toast.success("Product(s) removed from cart");

      // Manually dispatch an action to update the cart data in the store

      if (response.status=== 200) {
        console.log(id)
        // Manually dispatch an action to update the cart data in the store
        dispatch(fetchCartData(id));
      }

      return response.data; // Assuming the API returns the updated cart data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a cart slice
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /// Add to Cart
    builder
      .addCase(addToCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update the cart items in the state with the updated data
        state.cartItems = action.payload;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

    //// Fetch Data For cart
    builder
      .addCase(fetchCartData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCartData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cartItems = action.payload; // Update cartItems with fetched data
      })
      .addCase(fetchCartData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

    //// Delete data from cart
    builder
      .addCase(removeFromCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update the cart items in the state with the updated data
        state.cartItems = action.payload;
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default cartSlice.reducer;
