import { configureStore } from "@reduxjs/toolkit";
import Product from "../Feature/Products/ProductSlice";
import contestants from "../Feature/contest/Contest";
import cartReducer from "../Feature/Products/cartSlice";

export const store = configureStore({
  reducer: {
    getProduct: Product,
    getContestants: contestants,
    cart: cartReducer,
  },
});
