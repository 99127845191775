import React from "react";
import "./Benifits.css";
import benifits1 from "../../Images/benifits1.png";
import benifits2 from "../../Images/benifits2.png";
import benifits3 from "../../Images/benifits3.png";
import { useTranslation } from "react-i18next";

const Benifits = () => {
  const { t } = useTranslation("Translate");
  return (
    <>
      <div className="container-fluid py-5" id="benifits">
        <div className="heading">{t("Benefits")}</div>
        <div className="secondary-text text-center py-3">
          {/* There are several benefits to joining the barber online community
          offered by Beard Friends. */}
          {t("Benefits-P1")}
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xl-4 d-flex flex-column">
              <img 
              src={benifits1} 
              alt="benifit img" 
              className="benifit-img mx-auto" />
              <div className="benifits-text my-3">
                {/* Users can find nearby barber shops and connect with other
                like-minded individuals who are passionate about proper beard
                maintenance */}
                {t("Benefits-C1")}
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xl-4 d-flex flex-column">
              <img 
              src={benifits2} 
              alt="benifit img" 
              className="benifit-img mx-auto" />
              <div className="benifits-text my-2">
                {/* The community offers a platform to participate in beard contests
                and showcase your grooming skills */}
                {t("Benefits-C2")}
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xl-4 d-flex flex-column align-items-center">
              <img
                src={benifits3}
                alt="benefit img"
                className="benifit-img mx-auto"
              />
              <div className="benifits-text my-4 text-center">
                {t("Benefits-C3")}
              </div>
            </div>
          </div>
        </div>
        <div className=" container secondary-text">
          {/* Additionally, the community provides a wealth of resources and
          knowledge, including tips, advice, and tutorials from experienced
          professionals and fellow enthusiasts. Joining this community can help
          individuals stay up-to-date with the latest trends and techniques,
          connect with others who share their interests, and take their beard
          grooming to the next level. */}
          {t("Benefits-P2")}
        </div>
      </div>
    </>
  );
};

export default Benifits;
