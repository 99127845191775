import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "../src/App/Store";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import Language from "../src/Languages/AdrewLanguage.json";
import GrLanguage from "../src/Languages/AndrewGrLanguage.json";



   //////////////////////////  Function to convert to languages ///////////////////////
i18n.init({
  lng: "en",
  debug: true,
  resources: {
    en: {
      Translate: Language,
    },
    gr: {
      Translate: GrLanguage,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <React.StrictMode>
          <Provider store={store}>
            <App />
          </Provider>
        </React.StrictMode>
      </BrowserRouter>
    </I18nextProvider>
  </>
);

reportWebVitals();
