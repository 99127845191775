import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../Component/API/ApiUrl";

const initialState = {
  value: [],
  loading: false,
  error: null,
};

//actions to fetch data
export const getContestant = createAsyncThunk("getContestant", async () => {
  const response = await axios.get(`https://api.bartfreunde.de/api/auth/getOnGoingContest`);
  console.log(response.data, "contest recieve in thunk");
  const result = response.data;
  return result;
});

export const ProductSlice = createSlice({
  name: "contestants",
  initialState,

  //extra reducers are used to add extra functionality
  extraReducers: {
    [getContestant.pending]: (state) => {
      state.loading = true;
    },
    [getContestant.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload);
      state.value = action.payload.participants;
    },
    [getContestant.rejected]: (state, action) => {
      state.loading = false;
      state.value = action.payload;
    },
  },
});

export default ProductSlice.reducer;
