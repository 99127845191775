import React, { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import BeardFriends from "../Component/BeardFriends/BeardFriends";
import AboutCommunity from "../Component/AboutComunity/AboutComunity";
import MobileApp from "../Component/MobileApp/MobileApp";
import ContactUs from "../Component/ContactUs/ContactUs";
import Footer from "../Component/Footer/Footer";
import logo from "../Images/Logo.png";
import { BASEURL } from "../Component/API/ApiUrl";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Benifits from "../Component/Benifits/Benifits";
import LP2 from "../Images/LP2.png";

import { useSSR, useTranslation } from "react-i18next";
import { FadeLoader } from "react-spinners";
const AboutUs = () => {
  const { t } = useTranslation("Translate");
  const [verified, setVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const verifyUser = async () => {
    try {
      const response = await axios.get(`https://api.bartfreunde.de/api/auth/verifyUser/${id}`);
      console.log(response);
      if (response?.status == 200) {
        setVerified(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    verifyUser();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="flex justify-center items-center h-screen mx-auto loading-container"
          style={{
            backgroundColor: "#1c1c1c",
            height: "100vh",
          }}
        >
          <FadeLoader color="#ba5eef" />
        </div>
      ) : (
        <section
          className="container-fluid py-5 "
          id="Beard-friends "
          style={{
            backgroundColor: "#1C1C1E",
          }}
        >
          <div className="row" id="beardfriendrow">
            {verified ? (
              <div
                className=""
                id=""
                style={{
                  // border: "1px solid red",
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                {/* <img src={logo} alt="logo" className="" /> */}
                {/* <span className="text-white fs-3 fw-bold pt-2"> */}
                {/* Beard Friends */}
                {/* {t("BeardFriends")} */}
                {/* </span> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0,0,256,256"
                >
                  <g
                    fill="#1c1c1e"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <path d="M0,256v-256h256v256z" id="bgRectangle"></path>
                  </g>
                  <g
                    fill="#ffffff"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <g transform="scale(4,4)">
                      <path d="M32,6c-14.359,0 -26,11.641 -26,26c0,14.359 11.641,26 26,26c14.359,0 26,-11.641 26,-26c0,-14.359 -11.641,-26 -26,-26zM45.121,28.121l-13,13c-0.586,0.586 -1.353,0.879 -2.121,0.879c-0.768,0 -1.535,-0.293 -2.121,-0.879l-8,-8c-1.172,-1.171 -1.172,-3.071 0,-4.242c1.172,-1.172 3.07,-1.172 4.242,0l5.879,5.879l10.879,-10.879c1.172,-1.172 3.07,-1.172 4.242,0c1.172,1.171 1.172,3.071 0,4.242z"></path>
                    </g>
                  </g>
                </svg>
                <span className="text-white fs-3 fw-bold pt-2">Verified</span>
                <span className="text-white pt-1">
                  You can now sign in to the mobile app using your credentials
                </span>
              </div>
            ) : (
              <div
                className=""
                id=""
                style={{
                  // border: "1px solid red",
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                {/* <img src={logo} alt="logo" className="" /> */}
                {/* <span className="text-white fs-3 fw-bold pt-2"> */}
                {/* Beard Friends */}
                {/* {t("BeardFriends")} */}
                {/* </span> */}
                <ImCross color="#EBEBE7" size="80px" />
                <span className="text-white fs-3 fw-bold pt-2">
                  Not Verified
                </span>
                <span className="text-white pt-1">Something went wrong</span>
              </div>
            )}
          </div>

          <Footer />
        </section>
      )}

      {/* <BeardFriends img1={LP2} /> */}
      {/* <ContactUs /> */}
    </>
  );
};

export default AboutUs;
