import React, { useState } from "react";
import "./Imprint.css";
import { useLocation } from "react-router-dom";


const Imprint = () => {
  const { state } = useLocation();
  const [currentLang, setCurrentLang] = useState(state?.lang);


  return (
    <div className="main-container">
      {currentLang === "en" ? (
        <div>
          <h2 className="text-white">Imprint</h2>
          <div className="policy-section">
            <h4>Contact Information</h4>
            <p>
              Andre Genze<br />
              Röntgenstraße 5<br />
              52525 Heinsberg<br />
              Deutschland<br />
              E-Mail: <a href="mailto:hallo@bartfreunde.de">hallo@bartfreunde.de</a>
            </p>
          </div>
          <div className="policy-section">
            <h4>Sales tax identification number:</h4>
            <p>Will be provided shortly</p>
          </div>
          <div className="policy-section">
            <h4>Business identification number:</h4>
            <p>Will be provided shortly</p>
          </div>
          <div className="policy-section">
            <h4>Responsible for journalistic-editorial content:</h4>
            <p>Andre Genze, address see above</p>
          </div>
          <div className="policy-section">
            <h4>Disclaimer of Liability</h4>
            <p>
              <strong>Liability for content:</strong> The content of our website has been compiled with the utmost care. However, we cannot accept any liability for the accuracy, completeness, and topicality of the content. As a service provider, we are responsible for our own content on these pages in accordance with § 7 para.1 TMG (German Telemedia Act) and general laws. According to §§ 8 to 10 TMG, however, we as a service provider are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general legislation remain unaffected by this. However, liability in this respect is only possible from the time of knowledge of a specific infringement. As soon as we become aware of such infringements, we will remove this content immediately.
            </p>
            <p>
              <strong>Liability for links:</strong> Our website contains links to external third-party websites over whose content we have no influence. Therefore, we cannot accept any liability for this third-party content. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognisable at the time of linking. However, permanent monitoring of the content of the linked pages is not reasonable without concrete evidence of an infringement. If we become aware of any legal infringements, we will remove such links immediately.
            </p>
          </div>
          <div className="policy-section">
            <h4>Copyright</h4>
            <p>
              The content and works created by the site operators on these pages are subject to German copyright law. Reproduction, editing, distribution and any kind of utilisation outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is labelled as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.
            </p>
          </div>
          <div className="policy-section">
            <h4>Data Protection</h4>
            <p>
              The use of our website is generally possible without providing personal data. Insofar as personal data (e.g. name, address or e-mail addresses) is collected on our website, this is always done on a voluntary basis as far as possible. This data will not be passed on to third parties without your express consent. We would like to point out that data transmission over the Internet (e.g. when communicating by e-mail) may be subject to security vulnerabilities. Complete protection of data against access by third parties is not possible. We hereby expressly prohibit the use of contact data published within the scope of the imprint obligation by third parties for sending unsolicited advertising and information material. The operators of this website expressly reserve the right to take legal action in the event of the unsolicited sending of advertising information, such as spam e-mails.
            </p>
          </div>
          <div className="policy-section">
            <h4>Information on Online Dispute Resolution</h4>
            <p>
              The European Commission provides a platform for online dispute resolution (OS), which you can find here: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>. We are neither willing nor obliged to participate in dispute resolution proceedings before a consumer arbitration board.
            </p>
          </div>
        </div>
      ):(
        <div>
          <h2>Impressum</h2>
          <div className="policy-section">
            <h4>Kontakt Information</h4>
            <p>
              Andre Genze<br />
              Röntgenstraße 5<br />
              52525 Heinsberg<br />
              Deutschland<br />
              E-Mail: <a href="mailto:hallo@bartfreunde.de">hallo@bartfreunde.de</a>
            </p>
          </div>
          <div className="policy-section">
            <h4>Umsatzsteuer-Identifikationsnummer:</h4>
            <p>Wird in Kürze bereitgestellt</p>
          </div>
          <div className="policy-section">
            <h4>Betriebsnummer:</h4>
            <p>Wird in Kürze bereitgestellt</p>
          </div>
          <div className="policy-section">
            <h4>Verantwortlich für journalistisch-redaktionelle Inhalte:</h4>
            <p>Andre Genze, Adresse siehe oben</p>
          </div>
          <div className="policy-section">
            <h4>Haftungsausschluss</h4>
            <p>
              <strong>Haftung für Inhalte:</strong> Die Inhalte unserer Website wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG (Telemediengesetz) für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <p>
              <strong>Haftung für Links:</strong> Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
          </div>
          <div className="policy-section">
            <h4>Urheberrecht</h4>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
          </div>
          <div className="policy-section">
            <h4>Datenschutz</h4>
            <p>
              Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
            </p>
          </div>
          <div className="policy-section">
            <h4>Information zur Online-Streitbeilegung</h4>
            <p>
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>. Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
          </div>
        </div>
      )}
    
    </div>
  );
};

export default Imprint;
