import React from "react";
import "./MobileApp.css";
import googlestore from "../../Images/googlestore.png";
import appstore from "../../Images/appstore.png";
import mobiles from "../../Images/mobiles.png";
import { useTranslation } from "react-i18next";

const MobileApp = (props) => {
  const { t } = useTranslation("Translate");
  return (
    <>
      <div className="container-fluid px-5 py-5" id="mobileapp">
        <span
          className="heading d-flex  justify-content-center pt-3"
          id="download"
        >
          {t("DownloadApp")}
        </span>
        <div className="conatainer-fluid">
          <div className="row">
            <div className="col-sm-6 col-md-6 d-flex justify-content-center">
              <div className="download-content">
                <div className="secondary-text">{props.description}</div>
                <div className="google md:my-5">
                  <a
                    href="#"
                    target="1"
                  >
                    <img
                      src={googlestore}
                      alt="app and google store "
                      className="google-img img-fluid"
                    />
                  </a>
                  <a href="">
                    <img
                      src={appstore}
                      alt="app and google store "
                      className="google-img img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 ">
              <div className="mobiles">
                <img src={mobiles} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileApp;
