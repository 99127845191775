import React from "react";
import { useNavigate } from "react-router-dom";
import "./BeardFriends.css";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";


const BeardFriends = (props) => {

  const { t } = useTranslation("Translate");
  const isLoggedIn = localStorage.getItem("userlogin");

  const navigate = useNavigate();

  ///////////////////////////////  handle login here ///////////////////////////////

  const handleClick = () => {
    if (!isLoggedIn) {
      navigate("/memberlogin");
    } else {
      toast.warn("User Already Logged In", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  // handle download here

  const handleDownloadClick = () => {
    navigate("/");
  };

  return (
    <>
      <section className="container-fluid py-5" id="Beard-friends">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        
        <div className="container-fluid px-5 pb-3" id="BF-content">
          <div className="row" id="beardfriendrow">
            <div
              className="col-sm-6 col-md-6 col-xl-6 order-1"
              id="beardfriendcol"
            >
              <div className="beard-content">
                <span
                  className=" text-white heading animate__animated animate__fadeInUp"
                  id="beard-friends"
                >
                  {t("BeardFriends")}
                </span>

                <span className="secondary-text animate__animated animate__backInDown">
                  {t("Beard-P")}
                </span>

                {isLoggedIn ? (
                  <button className="btn-1" onClick={handleDownloadClick}>
                    {t("Download")}
                    {/* Download App */}
                  </button>
                ) : (
                  <button className="btn-1" onClick={handleClick}>
                    {t("Register")}
                    {/* Download App */}
                  </button>
                )}
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 order-2 pb-2">
              <img src={props.img1} alt="" id="LP1" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BeardFriends;
