import React, { useState } from 'react';
import './TermsAndConditions.css';
import { useLocation } from 'react-router-dom';

const TermsAndConditions = () => {
  const {state} = useLocation()
  const [currentLang, setCurrentLang] = useState(state?.lang);
  console.log(currentLang)
  return (
      <>
        {currentLang === 'en' ? (
      <div className="terms-container">
        <h1>Terms and Conditions</h1>
        <p>Last updated: [Date]</p>
  
        <section>
          <h2>1. Introduction</h2>
          <p>Welcome to [Company Name]. These Terms and Conditions govern your use of our website and services...</p>
        </section>
  
        <section>
          <h2>2. Intellectual Property Rights</h2>
          <p>Other than the content you own, under these Terms, [Company Name] and/or its licensors own all the intellectual property rights and materials contained in this Website...</p>
        </section>
  
        <section>
          <h2>3. Restrictions</h2>
          <ul>
            <li>You are specifically restricted from all of the following...</li>
            <li>Publishing any Website material in any other media...</li>
            <li>Selling, sublicensing, and/or otherwise commercializing any Website material...</li>
          </ul>
        </section>
  
        <section>
          <h2>4. Your Privacy</h2>
          <p>Please read our Privacy Policy...</p>
        </section>
  
        <section>
          <h2>5. Limitation of Liability</h2>
          <p>In no event shall [Company Name], nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this Website...</p>
        </section>
  
        <section>
          <h2>6. Indemnification</h2>
          <p>You hereby indemnify to the fullest extent [Company Name] from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms...</p>
        </section>
  
        <section>
          <h2>7. Severability</h2>
          <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein...</p>
        </section>
  
        <section>
          <h2>8. Variation of Terms</h2>
          <p>[Company Name] is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis...</p>
        </section>
  
        <section>
          <h2>9. Assignment</h2>
          <p>The [Company Name] is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification...</p>
        </section>
  
        <section>
          <h2>10. Entire Agreement</h2>
          <p>These Terms constitute the entire agreement between [Company Name] and you in relation to your use of this Website and supersede all prior agreements and understandings...</p>
        </section>
  
        <section>
          <h2>11. Governing Law & Jurisdiction</h2>
          <p>These Terms will be governed by and interpreted in accordance with the laws of the State of [State], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [State] for the resolution of any disputes...</p>
        </section>
      </div>):(
        <div className="terms-container">
      <h1>Allgemeine Geschäftsbedingungen</h1>
      <p>Zuletzt aktualisiert: [Datum]</p>

      <section>
        <h2>1. Einführung</h2>
        <p>Willkommen bei [Firmenname]. Diese Allgemeinen Geschäftsbedingungen regeln Ihre Nutzung unserer Website und Dienstleistungen...</p>
      </section>

      <section>
        <h2>2. Urheberrechte</h2>
        <p>Abgesehen von den Inhalten, die Sie besitzen, besitzen [Firmenname] und/oder seine Lizenzgeber gemäß diesen Bedingungen alle Urheberrechte und Materialien, die auf dieser Website enthalten sind...</p>
      </section>

      <section>
        <h2>3. Einschränkungen</h2>
        <ul>
          <li>Sie sind insbesondere von Folgendem ausgeschlossen...</li>
          <li>Veröffentlichung von Website-Material in anderen Medien...</li>
          <li>Verkauf, Unterlizenzierung und/oder anderweitige Kommerzialisierung von Website-Material...</li>
        </ul>
      </section>

      <section>
        <h2>4. Ihre Privatsphäre</h2>
        <p>Bitte lesen Sie unsere Datenschutzerklärung...</p>
      </section>

      <section>
        <h2>5. Haftungsbeschränkung</h2>
        <p>In keinem Fall haften [Firmenname] oder einer seiner Offiziere, Direktoren und Mitarbeiter für irgendetwas, das aus oder in irgendeiner Weise im Zusammenhang mit Ihrer Nutzung dieser Website entsteht...</p>
      </section>

      <section>
        <h2>6. Entschädigung</h2>
        <p>Sie stellen [Firmenname] hiermit in vollem Umfang von jeglichen und allen Verbindlichkeiten, Kosten, Forderungen, Klagegründen, Schäden und Ausgaben frei, die in irgendeiner Weise im Zusammenhang mit Ihrem Verstoß gegen eine der Bestimmungen dieser Bedingungen entstehen...</p>
      </section>

      <section>
        <h2>7. Salvatorische Klausel</h2>
        <p>Sollte eine Bestimmung dieser Bedingungen nach geltendem Recht ungültig sein, werden solche Bestimmungen gelöscht, ohne die verbleibenden Bestimmungen hierdurch zu beeinträchtigen...</p>
      </section>

      <section>
        <h2>8. Änderung der Bedingungen</h2>
        <p>[Firmenname] ist berechtigt, diese Bedingungen jederzeit nach eigenem Ermessen zu überarbeiten, und durch die Nutzung dieser Website wird von Ihnen erwartet, diese Bedingungen regelmäßig zu überprüfen...</p>
      </section>

      <section>
        <h2>9. Abtretung</h2>
        <p>[Firmenname] darf seine Rechte und/oder Pflichten gemäß diesen Bedingungen ohne Benachrichtigung abtreten, übertragen und untervergeben...</p>
      </section>

      <section>
        <h2>10. Gesamte Vereinbarung</h2>
        <p>Diese Bedingungen stellen die gesamte Vereinbarung zwischen [Firmenname] und Ihnen in Bezug auf Ihre Nutzung dieser Website dar und ersetzen alle vorherigen Vereinbarungen und Absprachen...</p>
      </section>

      <section>
        <h2>11. Anwendbares Recht & Gerichtsstand</h2>
        <p>Diese Bedingungen unterliegen den Gesetzen des Staates [Staat] und werden in Übereinstimmung mit diesen ausgelegt, und Sie unterwerfen sich der nicht ausschließlichen Zuständigkeit der Staats- und Bundesgerichte in [Staat] zur Beilegung von Streitigkeiten...</p>
      </section>
    </div>)
    }
      </>
    
  );
};

export default TermsAndConditions;
